import { FC, useState } from "react";
import { Box, Typography, Snackbar, Alert } from "@mui/material";
import { BookingsRequestParams, useGetBookingsQuery } from "shared/api";
import { getGraphicSvg, Colors } from "shared/themes";
import { BookingCard } from "./BookingCard";

interface AthleteBookingScheduleProps {
  closeSidebar: () => void;
}

const AthleteBookingSchedule: FC<AthleteBookingScheduleProps> = ({
  closeSidebar,
}) => {
  const calendarSvg = getGraphicSvg("calendar");
  const eventsSvg = getGraphicSvg("events");
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(5);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const {
    data: usersUpcomingBookings,
    isLoading: isLoadingUsersUpcomingBookings,
  } = useGetBookingsQuery({
    page,
    count,
    myUpcomingBookings: true,
  } as BookingsRequestParams);

  const {
    data: bookingsThatTheUserIsNotAttending,
    isLoading: isLoadingBookingsThatTheUserIsNotAttending,
  } = useGetBookingsQuery({
    page,
    count,
    filterOutBookingsThatUserIsAttending: true,
  } as BookingsRequestParams);

  const handleActionSuccess = (message: string, isError = false) => {
    if (isError) {
      setErrorMessage(message);
    } else {
      setSuccessMessage(message);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        paddingBottom: "60px",
      }}
    >
      {/* Header Section */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "24px 24px",
        }}
      >
        <Typography sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}>
          Your schedule
        </Typography>
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "100%",
            color: Colors.blue[1500],
            mb: 3,
          }}
        >
          Upcoming bookings
        </Typography>

        {/* User's Upcoming Bookings */}
        <Box>
          {isLoadingUsersUpcomingBookings && (
            <Typography>Loading...</Typography>
          )}
          {!isLoadingUsersUpcomingBookings &&
            usersUpcomingBookings &&
            usersUpcomingBookings.length > 0 &&
            usersUpcomingBookings.map((booking) => (
              <BookingCard
                key={booking.id}
                booking={booking}
                onActionComplete={handleActionSuccess}
                showLeaveButton
              />
            ))}
          {!isLoadingUsersUpcomingBookings &&
            (!usersUpcomingBookings || usersUpcomingBookings.length === 0) && (
              <Box
                sx={{
                  textAlign: "center",
                  mt: 5,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                <Box
                  component="img"
                  src={calendarSvg}
                  alt="Calendar no data"
                  sx={{ height: "100px", mb: 4 }}
                />
                <Typography>There are no bookings in your schedule.</Typography>
              </Box>
            )}
        </Box>

        {/* Available Bookings Section */}
        <Typography
          variant="h2"
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "24px",
            lineHeight: "100%",
            color: Colors.blue[1500],
            mt: 4,
            mb: 3,
          }}
        >
          Available sessions
        </Typography>

        <Box>
          {isLoadingBookingsThatTheUserIsNotAttending && (
            <Typography>Loading...</Typography>
          )}
          {!isLoadingBookingsThatTheUserIsNotAttending &&
            bookingsThatTheUserIsNotAttending &&
            bookingsThatTheUserIsNotAttending.length > 0 &&
            bookingsThatTheUserIsNotAttending.map((booking) => (
              <BookingCard
                key={booking.id}
                booking={booking}
                onActionComplete={handleActionSuccess}
                showLeaveButton={false}
              />
            ))}
          {!isLoadingBookingsThatTheUserIsNotAttending &&
            (!bookingsThatTheUserIsNotAttending ||
              bookingsThatTheUserIsNotAttending.length === 0) && (
              <Box
                sx={{
                  textAlign: "center",
                  mt: 5,
                  color: "rgba(0, 0, 0, 0.6)",
                }}
              >
                <Box
                  component="img"
                  src={eventsSvg}
                  alt="Calendar no data"
                  sx={{ height: "100px", mb: 4 }}
                />
                <Typography>
                  There are no bookings available to book in the timetable.
                </Typography>
              </Box>
            )}
        </Box>
      </Box>

      {/* Snackbars */}
      <Snackbar
        open={Boolean(successMessage)}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSuccessMessage("")}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>

      <Snackbar
        open={Boolean(errorMessage)}
        autoHideDuration={6000}
        onClose={() => setErrorMessage("")}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setErrorMessage("")}
          severity="error"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export { AthleteBookingSchedule };
