import { FC } from "react";
import { Box, Icon, Typography } from "@mui/material";
import { WorkoutLoadStatus } from "shared/api";
import { Colors } from "../../../../shared/themes";

interface WorkoutTrendIconProps {
  finalTrend: WorkoutLoadStatus;
}

const WorkoutTrendIcon: FC<WorkoutTrendIconProps> = ({
  finalTrend = WorkoutLoadStatus.Undefined,
}) => {
  if (finalTrend === WorkoutLoadStatus.Increasing) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Icon
          sx={{
            color: `${Colors.green[100]}`,
            fontSize: "16px",
            mr: "4px",
            ml: "4px",
          }}
        >
          trending_up
        </Icon>
        <Typography
          sx={{
            color: `${Colors.green[100]}`,
            fontSize: "12px",
            marginLeft: "4px",
          }}
        >
          Gaining momentum
        </Typography>
      </Box>
    );
  }
  if (finalTrend === WorkoutLoadStatus.Decreasing) {
    return (
      <Box display="flex" flexDirection="row" alignItems="center">
        <Icon
          sx={{
            color: `${Colors.red[200]}`,
            fontSize: "16px",
            mr: "4px",
            ml: "4px",
          }}
        >
          trending_down
        </Icon>
        <Typography
          sx={{
            color: `${Colors.red[200]}`,
            fontSize: "12px",
            marginLeft: "4px",
          }}
        >
          Slight decline - keep an eye on it
        </Typography>
      </Box>
    );
  }
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Icon
        sx={{
          color: `${Colors.yellow[200]}`,
          fontSize: "16px",
          mr: "4px",
          ml: "4px",
        }}
      >
        trending_flat
      </Icon>
      <Typography
        sx={{
          color: `${Colors.yellow[200]}`,
          fontSize: "12px",
          marginLeft: "4px",
        }}
      >
        Consistent progress
      </Typography>
    </Box>
  );
};

export { WorkoutTrendIcon };
