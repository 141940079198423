import { FC } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Select,
  MenuItem,
  useMediaQuery,
} from "@mui/material";

interface TimelineTypeRadioGroupProps {
  value: string;
  setValue: (value: string) => void;
}

const TimelineTypeRadioGroup: FC<TimelineTypeRadioGroupProps> = ({
  value,
  setValue,
}) => {
  const isMobile = useMediaQuery("(max-width:600px)");

  if (isMobile) {
    return (
      <FormControl fullWidth>
        <Select
          value={value}
          onChange={(e) => setValue(e.target.value)}
          size="small"
          displayEmpty
        >
          <MenuItem value="">All</MenuItem>
          <MenuItem value="Group">Group</MenuItem>
          <MenuItem value="Private">Private</MenuItem>
        </Select>
      </FormControl>
    );
  }

  return (
    <FormControl>
      <RadioGroup
        row
        value={value}
        onChange={(e) => setValue(e.target.value)}
        sx={{
          "& .MuiFormControlLabel-root": {
            mr: 3,
          },
        }}
      >
        <FormControlLabel
          value="Group"
          control={<Radio size="small" />}
          label="Group"
        />
        <FormControlLabel
          value="Private"
          control={<Radio size="small" />}
          label="Private"
        />
        <FormControlLabel
          value=""
          control={<Radio size="small" />}
          label="All"
        />
      </RadioGroup>
    </FormControl>
  );
};

export { TimelineTypeRadioGroup };
