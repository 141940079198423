/* eslint-disable react/jsx-no-useless-fragment */
import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Typography, Icon, Collapse, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Italic from "@tiptap/extension-italic";
import { Colors } from "../../../../shared/themes";
import { WorkoutListViewItem } from "./WorkoutListViewItem";
import {
  useGetCombinedWorkoutItemsQuery,
  CombinedData,
  WorkoutSession,
  WorkoutProgramStatus,
} from "../../../../shared/api";
import { WorkoutPageSkeleton } from "./WorkoutPageSkeleton";
import { useTypedSelector } from "../../../../shared/stores";
import { userSelectors } from "../../../../shared/stores/user";

interface WorkoutListProps {
  workout: WorkoutSession;
}

const { IN_PROGRESS, COMPLETED } = WorkoutProgramStatus;

// Add this new component for displaying rich text content
const RichTextDisplay = ({ content }: { content: string }) => {
  const [expanded, setExpanded] = useState(true);
  const editor = useEditor({
    extensions: [StarterKit, Underline, Italic],
    content,
    editable: false,
  });

  return (
    <Box
      sx={{
        backgroundColor: "white",
        padding: 1.5,
        borderRadius: "12px",
        mb: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "140%",
            color: `${Colors.oxford[1100]}`,
            opacity: "0.87",
            textDecoration: "none",
            paddingTop: 1,
            paddingBottom: 1,
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon
            className="material-icons-outlined"
            sx={{
              fontSize: "16px !important",
              mr: "4px",
              ml: "4px",
              color: `${Colors.oxford[1100]}`,
              opacity: "0.87",
            }}
          >
            sms
          </Icon>
          Session Notes
        </Typography>
        <IconButton
          size="small"
          sx={{ padding: 0.5 }}
          onClick={(e) => {
            e.stopPropagation();
            setExpanded(!expanded);
          }}
        >
          <Icon
            className="material-icons-outlined"
            sx={{
              fontSize: "20px !important",
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s",
            }}
          >
            expand_more
          </Icon>
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box
          sx={{
            "& .ProseMirror": {
              minHeight: "auto",
              padding: "0",
              "& p": {
                margin: 0,
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "140%",
                color: `${Colors.oxford[1100]}`,
              },
            },
          }}
        >
          <EditorContent editor={editor} />
        </Box>
      </Collapse>
    </Box>
  );
};

const WorkoutList: FC<WorkoutListProps> = ({ workout }) => {
  const { t } = useTranslation();

  const userId = useTypedSelector(userSelectors.userId);

  const { data: combinedData, isLoading } = useGetCombinedWorkoutItemsQuery({
    userId,
    workoutId: workout.id,
  });

  const isInProgress = workout?.workoutStatus === IN_PROGRESS;

  const isNotCompleted = workout?.workoutStatus !== COMPLETED;

  const groupedExercises = combinedData?.reduce<Record<string, CombinedData[]>>(
    (groups, exercise) => {
      const key = exercise.supersetGroup || exercise.exerciseId.toString(); // Using exercise.id as a fallback key
      if (!groups[key]) {
        groups[key] = [];
      }
      groups[key].push(exercise);
      return groups;
    },
    {}
  ) ?? {
    "": [],
  };

  const groupedExercisesArray = Object.values(groupedExercises).sort((a, b) => {
    const orderA = a[0]?.order || 0;
    const orderB = b[0]?.order || 0;
    return orderA - orderB;
  });

  return (
    <Box
      sx={{
        width: "100%",
        padding: `23px 24px ${isNotCompleted ? "100px" : "0px"}`,
        height: "70vh",
        overflowY: "auto",
      }}
    >
      {workout?.description && (
        <RichTextDisplay content={workout.description} />
      )}

      <Typography
        sx={{
          fontFamily: "Inter",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "140%",
          color: `${Colors.gray[1200]}`,
          textTransform: "none",
          marginBottom: "12px",
          marginTop: "24px",
        }}
      >
        {t("session-card.exercises")}
      </Typography>

      {isLoading ? (
        <WorkoutPageSkeleton />
      ) : (
        <>
          {groupedExercisesArray.map((exercises, index) => {
            if (exercises.length > 1) {
              return (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    mb: "20px",
                    boxShadow:
                      "0px 10px 40px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04)",
                    borderRadius: "12px",
                    p: "20px",
                    backgroundColor: "#FAFAFA",
                    border: "1px solid #F5F5F5",
                    boxSizing: `border-box`,
                  }}
                >
                  <Typography
                    variant="h5"
                    sx={{
                      fontFamily: "Inter",
                      fontStyle: "normal",
                      fontWeight: "500",
                      fontSize: "16px",
                      lineHeight: "140%",
                      color: `${Colors.blue[1300]}`,
                      mb: 2,
                    }}
                  >
                    Superset
                  </Typography>
                  {exercises.map((exercise: CombinedData) => (
                    <WorkoutListViewItem
                      key={exercise.exerciseId}
                      exercise={exercise}
                      selectedUserId={userId!!}
                    />
                  ))}
                </Box>
              );
            }
            return exercises.map((exercise: CombinedData) => (
              <WorkoutListViewItem
                key={exercise.exerciseId}
                exercise={exercise}
                selectedUserId={userId!!}
              />
            ));
          })}
        </>
      )}
    </Box>
  );
};

export { WorkoutList };
