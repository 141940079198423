// BookingScheduleTabs.tsx

import React, { FC, useState } from "react";
import { Box, ToggleButtonGroup, ToggleButton } from "@mui/material";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import LoadingSkeleton from "components/Skeleton/LoadingSkeleton";
import {
  useGetBookingsQuery,
  useGetBookingsCountQuery,
  BookingGroup,
  BookingsRequestParams,
  useGetUsersQuery,
  UserRole,
} from "shared/api";
import { BookingScheduleCalendarView } from "./BookingScheduleCalendarView";
import { BookingScheduleListView } from "./BookingScheduleListView";
import { StyledToggleButton } from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

interface BookingScheduleContainerProps {
  bookingGroups: BookingGroup[] | undefined;
}

const BookingScheduleContainer: FC<BookingScheduleContainerProps> = ({
  bookingGroups,
}) => {
  const [count, setCount] = useState(10);
  const [page, setPage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [view, setView] = useState<"calendar" | "list">("calendar");

  // Initialize start and end dates based on default tab (0 = calendar view)
  const [bookingsStartDate, setBookingsStartDate] = useState<
    string | undefined
  >(() => {
    if (tabIndex === 0) {
      const today = dayjs();
      return today.startOf("week").format("YYYY-MM-DD");
    }
    return undefined;
  });

  const [bookingsEndDate, setBookingsEndDate] = useState<string | undefined>(
    () => {
      if (tabIndex === 0) {
        const today = dayjs();
        return today.endOf("week").format("YYYY-MM-DD");
      }
      return undefined;
    }
  );

  const { data: bookingsCount, isLoading: isLoadingCount } =
    useGetBookingsCountQuery();

  const {
    data: bookings,
    isLoading,
    refetch,
  } = useGetBookingsQuery({
    ...(tabIndex === 0
      ? {
          startDate: bookingsStartDate,
          endDate: bookingsEndDate,
        }
      : {
          page,
          count,
        }),
  } as BookingsRequestParams);

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: "calendar" | "list" | null
  ) => {
    if (!newView) return; // Handle null case when deselecting
    setView(newView);
    setTabIndex(newView === "calendar" ? 0 : 1);
    // Reset parameters based on the new view
    if (newView === "calendar") {
      const today = dayjs();
      setBookingsStartDate(today.startOf("week").format("YYYY-MM-DD"));
      setBookingsEndDate(today.endOf("week").format("YYYY-MM-DD"));
    } else {
      setBookingsStartDate(undefined);
      setBookingsEndDate(undefined);
    }
  };

  const { data: nonAthleteUsers, isLoading: isLoadingUsers } = useGetUsersQuery(
    {
      orderByName: true,
      userRole: UserRole.Admin,
    }
  );

  if (isLoading || isLoadingUsers) {
    return <LoadingSkeleton />;
  }

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, mb: 2, alignItems: "center" }}>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          size="small"
        >
          <StyledToggleButton value="calendar">
            Calendar View
          </StyledToggleButton>
          <StyledToggleButton value="list">List View</StyledToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Box sx={{ mt: 2 }}>
        {tabIndex === 0 ? (
          <BookingScheduleCalendarView
            bookingGroups={bookingGroups}
            bookings={bookings}
            setBookingsStartDate={setBookingsStartDate}
            setBookingsEndDate={setBookingsEndDate}
            nonAthleteUsers={nonAthleteUsers}
          />
        ) : (
          <BookingScheduleListView
            bookingGroups={bookingGroups}
            bookings={bookings}
            bookingsCount={bookingsCount}
            count={count}
            setCount={setCount}
            refetch={refetch}
          />
        )}
      </Box>
    </Box>
  );
};

export { BookingScheduleContainer };
