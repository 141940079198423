import { FC, useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Button,
  Icon,
  Tab,
} from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useGetBookingGroupsQuery } from "shared/api";
import { BookingEventTypesContainer } from "./BookingEventTypesContainer";
import { BookingScheduleContainer } from "./BookingScheduleContainer";
import { CreateEventTypeModal } from "./CreateEventTypeModal";
import { CreateBookingModal } from "./CreateBookingModal";

interface BookingsContainerProps {
  openSidebar: () => void;
}

const BookingsContainer: FC<BookingsContainerProps> = ({ openSidebar }) => {
  const matches = useMediaQuery("(min-width:900px)");

  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );

  const { data: bookingGroups, isLoading: isLoadingBookingGroups } =
    useGetBookingGroupsQuery();

  const showSidebar = matches && isOpenSidebar;

  const [tabValue, setTabValue] = useState("1");

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  const [isAddEventTypeModalOpen, setIsAddEventTypeModalOpen] = useState(false);

  const handleCloseEventTypeModal = () => {
    setIsAddEventTypeModalOpen(false);
  };

  const [isAddBookingModalOpen, setIsAddBookingModalOpen] = useState(false);

  const handleCloseBookingModal = () => {
    setIsAddBookingModalOpen(false);
  };

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>Bookings</Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "auto",
            height: "auto",
            "& > *:not(:last-child)": {
              marginRight: "16px",
            },
          }}
        >
          {tabValue === "1" && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                color: `${Colors.gray[100]}`,
                textTransform: "none",
              }}
              onClick={() => setIsAddBookingModalOpen(true)}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                add_icon
              </Icon>
              New booking
            </Button>
          )}
          {tabValue === "2" && (
            <Button
              variant="contained"
              color="primary"
              sx={{
                fontFamily: "Inter",
                fontStyle: "normal",
                fontWeight: "500",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.4px",
                textTransform: "none",
              }}
              onClick={() => setIsAddEventTypeModalOpen(true)}
            >
              <Icon
                sx={{
                  marginRight: "8px",
                }}
              >
                add_icon
              </Icon>
              Create event type
            </Button>
          )}
        </Box>
      </Box>
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleTabChange} aria-label="Tabs">
            <Tab label="Schedule" value="1" sx={{ textTransform: "none" }} />
            <Tab label="Event types" value="2" sx={{ textTransform: "none" }} />
          </TabList>
        </Box>
        <TabPanel value="1" sx={{ padding: 0, paddingTop: 2 }}>
          <BookingScheduleContainer bookingGroups={bookingGroups} />
        </TabPanel>
        <TabPanel value="2" sx={{ padding: 0, paddingTop: 2 }}>
          <BookingEventTypesContainer
            bookingGroups={bookingGroups}
            isLoadingBookingGroups={isLoadingBookingGroups}
          />
        </TabPanel>
      </TabContext>
      <CreateEventTypeModal
        isOpenModal={isAddEventTypeModalOpen}
        handleCloseModal={handleCloseEventTypeModal}
      />
      <CreateBookingModal
        isOpenModal={isAddBookingModalOpen}
        handleCloseModal={handleCloseBookingModal}
        bookingGroups={bookingGroups}
      />
    </Box>
  );
};

export { BookingsContainer };
