/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useEffect, useRef, useState } from "react";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import {
  TimelineItem,
  useGetTimelineItemsCountQuery,
  useGetTimelineItemsQuery,
  useGetTimelineByIdQuery,
} from "shared/api";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { Skeleton, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Colors } from "shared/themes";
import { TimelineViewItemCard } from "../TimelineViewItemCard";
import { AthleteTimelineViewInput } from "./AthleteTimelineViewInput";

const PAGE_SIZE = 5;
const SCROLL_IS_NEAR_TOP_OFFSET = 40;

interface AthleteTimelineViewProps {
  closeSidebar: () => void;
}

const AthleteTimelineView: FC<AthleteTimelineViewProps> = ({
  closeSidebar,
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const timelineId = Number(params.timelineId);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [page, setPage] = useState(1);
  const [loadedItems, setLoadedItems] = useState<TimelineItem[]>([]);
  const [showLoadMoreButton, setShowLoadMore] = useState(false);
  const [replyTo, setReplyTo] = useState<TimelineItem>();
  const [isReply, setIsReply] = useState(false);

  const { data: timeline, isLoading: isLoadingTimeline } =
    useGetTimelineByIdQuery(timelineId ?? 0, { skip: !timelineId });

  const { data: timelineItemsDataForCount, isLoading: isLoadingItemsLength } =
    useGetTimelineItemsCountQuery({
      timelineId,
    });
  const timelineItemsLength = timelineItemsDataForCount || 0;
  const hasNextPage = timelineItemsLength > page * pageSize;
  const timelineItemsRef = useRef<HTMLDivElement>(null);

  const { data: timelineItemsData, isLoading: isLoadingItems } =
    useGetTimelineItemsQuery({
      timelineId,
      orderByDescending: true,
      count: pageSize,
      page,
    });

  const checkForLoadMore = () => {
    const element = timelineItemsRef.current;
    return element ? element.scrollTop <= SCROLL_IS_NEAR_TOP_OFFSET : false;
  };

  const handleScroll = () => {
    const isScrollNearTop = checkForLoadMore();
    setShowLoadMore(isScrollNearTop);
  };

  const scollToBottom = () => {
    const element = timelineItemsRef.current;
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  };

  useEffect(() => {
    scollToBottom();
  }, []);

  useEffect(() => {
    if (timelineItemsData) {
      setLoadedItems((prevLoadedItems) => [
        ...prevLoadedItems,
        ...timelineItemsData,
      ]);
    }
  }, [timelineItemsData]);

  useEffect(() => {
    if (timelineItemsData && page === 1) {
      setLoadedItems(timelineItemsData);
      setShowLoadMore(true);
      scollToBottom();
    }
  }, [loadedItems]);

  const handleLoadMore = () => {
    if (!isLoadingItems && hasNextPage) {
      setPage(page + 1);
      const isScrollNearTop = checkForLoadMore();
      setShowLoadMore(isScrollNearTop);
    }
  };

  const handleAddItem = () => {
    scollToBottom();
    setPage(1);
    setLoadedItems([]);
    setReplyTo(undefined);
    setIsReply(false);
  };

  const handleReplyComment = (timelineItem: TimelineItem) => {
    if (timelineItem?.id === replyTo?.id) {
      setReplyTo(undefined);
      setIsReply(false);
      return;
    }
    setReplyTo(timelineItem);
    setIsReply(true);
  };

  if (isLoadingItems || isLoadingTimeline || isLoadingItemsLength)
    return (
      <Skeleton
        variant="rectangular"
        animation="wave"
        sx={{ height: "131px", width: "100%", mb: "12px" }}
      />
    );

  const timelineUserIdRequiredForWorkoutPreview =
    timeline?.timelineType === "Private" && timeline?.userIds.length
      ? timeline.userIds[0]
      : 0;

  return (
    <Box
      sx={{
        width: "100%",
        background: "white",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 60,
      }}
    >
      <Box
        sx={{
          borderBottom: `1px solid ${Colors.gray[200]}`,
          padding: "16px 24px",
          backgroundColor: "white",
          zIndex: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "sticky",
          top: 0,
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: Colors.gray[600],
              mb: 0.5,
            }}
          >
            {timeline?.timelineType} Timeline
          </Typography>
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: 600,
              color: Colors.blue[1500],
            }}
          >
            {timeline?.name}
          </Typography>
        </Box>

        {hasNextPage && showLoadMoreButton && (
          <PrimaryButton
            size="small"
            type="button"
            value={t("timeline.load-more")}
            loading={isLoadingItems}
            disabled={isLoadingItems}
            variant="outlined"
            onClick={handleLoadMore}
            sx={{
              textTransform: "none",
              height: "36px",
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          display: "flex",
          flexDirection: "column-reverse",
          p: 2,
          gap: 1,
        }}
        onClick={closeSidebar}
        ref={timelineItemsRef}
        onScroll={handleScroll}
      >
        {!isLoadingItems &&
          loadedItems.map((item, index) => (
            <TimelineViewItemCard
              timelineItem={item}
              key={index}
              isClientView
              onReply={handleReplyComment}
              timelineUserId={timelineUserIdRequiredForWorkoutPreview}
            />
          ))}
      </Box>

      <Box
        sx={{
          backgroundColor: "white",
          borderTop: `1px solid ${Colors.gray[200]}`,
          position: "sticky",
          bottom: 0,
          zIndex: 1,
        }}
      >
        <AthleteTimelineViewInput
          timelineId={timelineId ?? 0}
          onAddItem={handleAddItem}
          replyTo={replyTo}
          isReply={isReply}
        />
      </Box>
    </Box>
  );
};

export { AthleteTimelineView };
