import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
  Select,
  MenuItem,
  ButtonGroup,
  Icon,
  Button,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { FC, useEffect, useState, useCallback, useRef } from "react";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { Colors } from "shared/themes";
import {
  Timeline,
  useClearAllUnreadNotificationsMutation,
  useGetTimelinesCountQuery,
  useGetTimelinesQuery,
} from "shared/api";
import { SnackbarAlert } from "components/SnackbarAlert/SnackbarAlert";
import SearchIcon from "@mui/icons-material/SearchOutlined";
import { TimelineTypeRadioGroup } from "components/RadioGroups/TimelineTypeRadioGroup";
import { TimelineCard } from "../TimelineCard";
import { TimelineSkeleton } from "./TimelineSkeleton";

const PAGE_SIZE = 9;
const PAGE_SIZE_OPTIONS = [9, 18, 27];

const TimelineContent: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [searchValue, setSearchValue] = useState<string>("");
  const [queryString, setQueryString] = useState<string>("");
  const [timelineType, setTimelineType] = useState<string>("");

  const { data: timelinesLength } = useGetTimelinesCountQuery({
    queryString,
    timelineType,
  });

  const { data, isLoading } = useGetTimelinesQuery({
    count: pageSize,
    page,
    orderByUnreadItems: true,
    queryString,
    timelineType,
  });

  const [
    trigger,
    { isLoading: isLoadingClearAllNotifications, isSuccess, isError },
  ] = useClearAllUnreadNotificationsMutation();

  const [timelinesData, setTimelinesData] = useState<Timeline[]>([]);
  const [showSnackbar, setShowSnackbar] = useState(false);

  const totalPages = Math.ceil((timelinesLength || 0) / pageSize);

  const matches = useMediaQuery("(min-width: 1280px)");

  const handleSearch = () => {
    setQueryString(searchValue);
    setPage(1);
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      handleSearch();
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const handleClearAllUnreadNotifications = () => {
    trigger();
    setShowSnackbar(true);
  };

  const handlePageSizeChange = (event: any) => {
    const newPageSize = Number(event.target.value);
    setPageSize(newPageSize);
    setPage(1); // Reset to first page when changing page size
  };

  const handleTimelineTypeChange = (value: string) => {
    setTimelineType(value);
    setPage(1);
  };

  useEffect(() => {
    if (!data) return;
    setTimelinesData(data);
  }, [data]);

  return (
    <Box
      sx={{
        ...sharedStyles.containers.dataGrid.wrapper,
        height: "unset",
      }}
    >
      <Box>
        <Box
          sx={{
            ...sharedStyles.containers.dataGrid.secondaryWrapper,
            justifyContent: "flex-end",
            mb: 4,
            mt: 1,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <TimelineTypeRadioGroup
              value={timelineType}
              setValue={handleTimelineTypeChange}
            />
            <TextField
              label="Search by user..."
              variant="standard"
              value={searchValue}
              onChange={handleOnChange}
              onKeyDown={handleSearchKeyDown}
              sx={{ minWidth: { xs: "100%", sm: "200px" } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSearch}
                      edge="end"
                      size="small"
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>

        <Box
          sx={{
            overflow: "auto",
            padding: "8px",
          }}
        >
          {isLoading ? (
            <TimelineSkeleton />
          ) : (
            <Grid container spacing={2}>
              {timelinesData?.map((item, key) => (
                <Grid item xs={12} sm={6} md={4} key={key}>
                  <TimelineCard timeline={item} />
                </Grid>
              ))}
            </Grid>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            justifyContent: "space-between",
            alignItems: { xs: "stretch", sm: "center" },
            gap: 2,
            mt: 2,
            pt: 2,
            borderTop: `1px solid ${Colors.gray[200]}`,
          }}
        >
          <LoadingButton
            variant="outlined"
            color="primary"
            type="button"
            onClick={handleClearAllUnreadNotifications}
            disabled={isLoading || isLoadingClearAllNotifications}
            loading={isLoading || isLoadingClearAllNotifications}
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.4px",
              textTransform: "none",
              paddingRight: "16px",
              width: { xs: "100%", sm: "auto" },
            }}
          >
            {t("timeline.mark-all-as-read")}
          </LoadingButton>

          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "stretch", sm: "center" },
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: { xs: "space-between", sm: "flex-start" },
              }}
            >
              <Typography variant="body2">Rows per page:</Typography>
              <Select
                value={pageSize}
                onChange={handlePageSizeChange}
                size="small"
                sx={{ minWidth: 80 }}
              >
                {PAGE_SIZE_OPTIONS.map((size) => (
                  <MenuItem key={size} value={size}>
                    {size}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                justifyContent: { xs: "space-between", sm: "flex-start" },
              }}
            >
              <Typography variant="body2">
                {`${(page - 1) * pageSize + 1}-${Math.min(
                  page * pageSize,
                  timelinesLength || 0
                )} of ${timelinesLength || 0}`}
              </Typography>
              <ButtonGroup size="small">
                <IconButton
                  onClick={() => setPage((p) => Math.max(1, p - 1))}
                  disabled={page === 1}
                >
                  <Icon>navigate_before</Icon>
                </IconButton>
                <IconButton
                  onClick={() => setPage((p) => Math.min(totalPages, p + 1))}
                  disabled={page >= totalPages}
                >
                  <Icon>navigate_next</Icon>
                </IconButton>
              </ButtonGroup>
            </Box>
          </Box>
        </Box>

        {isSuccess && showSnackbar && (
          <SnackbarAlert
            isOpen={isSuccess}
            onClose={() => setShowSnackbar(false)}
            severity="success"
            message="All notifications have been marked as read."
          />
        )}
        {isError && showSnackbar && (
          <SnackbarAlert
            isOpen={isError}
            onClose={() => setShowSnackbar(false)}
            severity="error"
            message="An error occurred while marking all notifications as read."
          />
        )}
      </Box>
    </Box>
  );
};

export { TimelineContent };
