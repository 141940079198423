import { FC, useState, useEffect } from "react";
import { Box } from "@mui/system";
import {
  Alert,
  Modal,
  Icon,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import { InputField } from "components/Form/InputField";
import { PrimaryButton } from "components/Form/PrimaryButton";
import {
  Booking,
  useUpdateBookingMutation,
  ClientResponse,
  usePermanentlyDeleteBookingMutation,
} from "shared/api";
import { Colors } from "shared/themes";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { MultiClientSelector } from "components/ClientSelector/MultiClientSelector";
import { sharedStyles } from "shared/themes/shared/styles";
import { StyledDivider, ModalSection } from "./styles";

interface UpdateBookingModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  booking: Booking | null;
  setBooking: React.Dispatch<React.SetStateAction<Booking | null>>;
}

const UpdateBookingModal: FC<UpdateBookingModalProps> = ({
  isOpenModal,
  handleCloseModal,
  booking,
  setBooking,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [updateBooking, { isLoading }] = useUpdateBookingMutation();
  const [permanentlyDeleteBooking, { isLoading: isDeleting }] =
    usePermanentlyDeleteBookingMutation();
  const [selectedCoaches, setSelectedCoaches] = useState<ClientResponse[]>([]);
  const [selectedAthletes, setSelectedAthletes] = useState<ClientResponse[]>(
    []
  );
  const [updateConfirmationOpen, setUpdateConfirmationOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [applyToFutureBookings, setApplyToFutureBookings] = useState(false);

  useEffect(() => {
    if (booking) {
      if (booking.coachAttendees) {
        setSelectedCoaches(booking.coachAttendees);
      }
      if (booking.athleteAttendees) {
        setSelectedAthletes(booking.athleteAttendees);
      }
    }
  }, [booking]);

  // Reset applyToFutureBookings when modal opens
  useEffect(() => {
    if (isOpenModal) {
      setApplyToFutureBookings(false);
    }
  }, [isOpenModal]);

  const formik = useFormik({
    initialValues: {
      name: booking?.name ?? "",
      description: booking?.description ?? "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string(),
    }),
    onSubmit: async (values) => {
      setUpdateConfirmationOpen(true);
    },
  });

  const handleConfirmedSubmit = async () => {
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const updatedBooking = {
        ...booking,
        ...formik.values,
        bookingAthleteAttendeeUserIds: selectedAthletes.map(
          (athlete) => athlete.id
        ),
        bookingCoachAttendeeUserIds: selectedCoaches.map((coach) => coach.id),
        applyChangesToFutureBookings: applyToFutureBookings,
      };
      const savedBooking = await updateBooking(updatedBooking).unwrap();
      setSuccessMessage("Booking updated successfully");
      setBooking(savedBooking as Booking);
      setApplyToFutureBookings(false); // Reset after successful update
      handleCloseModal();
    } catch (error) {
      console.error("Error updating booking:", error);
      setErrorMessage("An error occurred while updating the booking");
    } finally {
      setUpdateConfirmationOpen(false);
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
      }, 2500);
    }
  };

  const handlePermanentlyDeleteBooking = async () => {
    try {
      await permanentlyDeleteBooking({
        id: booking?.id as number,
        applyChangesToFutureBookings: applyToFutureBookings,
      }).unwrap();
      setSuccessMessage("Booking deleted successfully");
      setBooking(null);
      setSelectedAthletes([]);
      setSelectedCoaches([]);
      setApplyToFutureBookings(false); // Reset after successful delete
    } catch (error) {
      setErrorMessage("An error occurred while deleting the booking");
    } finally {
      setDeleteConfirmationOpen(false);
      setTimeout(() => {
        setErrorMessage("");
        setSuccessMessage("");
        handleCloseModal();
      }, 2500);
    }
  };

  return (
    <>
      <Modal open={isOpenModal} onClose={handleCloseModal}>
        <Box
          sx={{
            ...sharedStyles.containers.modal,
            alignItems: { xs: "flex-end", md: "center" },
          }}
        >
          <Box
            sx={{
              ...sharedStyles.containers.modalContent,
              width: { xs: "100%", md: "50%" },
              maxHeight: { xs: "90vh", md: "none" },
              borderRadius: { xs: "16px 16px0 0", md: "8px" },
              m: { xs: 0, md: 3 },
              position: "relative",
              overflow: "auto",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                mb: 2,
                position: "sticky",
                top: 0,
                bgcolor: "background.paper",
                pt: 1,
                zIndex: 1,
              }}
            >
              <Typography
                variant="h5"
                sx={{ fontWeight: 600, fontSize: { xs: "16px", md: "18px" } }}
              >
                {booking?.name}
              </Typography>
              <Icon
                onClick={handleCloseModal}
                sx={{ color: Colors.gray[600], cursor: "pointer" }}
              >
                close_icon
              </Icon>
            </Box>

            <Box component="form" onSubmit={formik.handleSubmit} noValidate>
              <ModalSection>
                <InputField
                  fullWidth
                  margin="normal"
                  label="Name"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />

                <InputField
                  fullWidth
                  margin="normal"
                  label="Description"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                  multiline
                  rows={2}
                />
              </ModalSection>

              <StyledDivider />

              <MultiClientSelector
                selectedClients={selectedCoaches}
                onClientsChange={setSelectedCoaches}
                searchMode="admin"
                label="Coach search"
                placeholder="Search to add multiple coaches..."
              />

              <MultiClientSelector
                selectedClients={selectedAthletes}
                onClientsChange={(newAthletes) => {
                  if (
                    !booking?.privateBooking &&
                    booking?.maxAttendees &&
                    newAthletes.length > booking.maxAttendees
                  ) {
                    setErrorMessage(
                      `You can select up to ${booking.maxAttendees} athletes.`
                    );
                  } else {
                    setSelectedAthletes(newAthletes);
                    setErrorMessage("");
                  }
                }}
                searchMode="athlete"
                label="Athlete search"
                placeholder="Search to add multiple athletes..."
              />

              <PrimaryButton
                size="large"
                value={`Update ${booking?.name ?? ""}`}
                loading={isLoading}
                disabled={isLoading}
                variant="contained"
                fullWidth
                type="submit"
              />

              <Button
                sx={{ mt: 2, textTransform: "none" }}
                color="error"
                variant="outlined"
                fullWidth
                onClick={() => setDeleteConfirmationOpen(true)}
              >
                Delete {booking?.name}
              </Button>

              {errorMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="error"
                >
                  {errorMessage}
                </Alert>
              )}

              {successMessage && (
                <Alert
                  sx={{
                    mt: 3,
                    width: "100%",
                  }}
                  variant="outlined"
                  severity="success"
                >
                  {successMessage}
                </Alert>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
      >
        <DialogTitle>Delete {booking?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to permanently delete this booking? This
            action cannot be undone.
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToFutureBookings}
                  onChange={(e) => setApplyToFutureBookings(e.target.checked)}
                />
              }
              label="Apply to all future bookings in this series?"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="button"
            onClick={() => setDeleteConfirmationOpen(false)}
            color="primary"
            sx={{ textTransform: "none" }}
            loading={isDeleting}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            onClick={handlePermanentlyDeleteBooking}
            color="error"
            variant="contained"
            sx={{ textTransform: "none" }}
            loading={isDeleting}
          >
            Delete {booking?.name}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Add Update Confirmation Dialog */}
      <Dialog
        open={updateConfirmationOpen}
        onClose={() => setUpdateConfirmationOpen(false)}
      >
        <DialogTitle>Update {booking?.name}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Do you want to apply these changes to future bookings in this
            series?
          </DialogContentText>
          <Box sx={{ mt: 2 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={applyToFutureBookings}
                  onChange={(e) => setApplyToFutureBookings(e.target.checked)}
                />
              }
              label="Apply to all future bookings in this series?"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="button"
            onClick={() => setUpdateConfirmationOpen(false)}
            color="primary"
            sx={{ textTransform: "none" }}
            loading={isLoading}
          >
            Cancel
          </LoadingButton>
          <LoadingButton
            type="button"
            onClick={handleConfirmedSubmit}
            color="primary"
            variant="contained"
            sx={{ textTransform: "none" }}
            loading={isLoading}
          >
            Update {booking?.name}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { UpdateBookingModal };
