import { FC } from "react";
import { Box } from "@mui/system";
import { Badge, Card, CardContent, Typography } from "@mui/material";
import { Colors } from "shared/themes";
import { Timeline } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Link } from "react-router-dom";
import { Routes } from "shared/routers";
import MailIcon from "@mui/icons-material/Mail";

interface TimelineCardProps {
  timeline: Timeline;
}

const TimelineCard: FC<TimelineCardProps> = ({ timeline }) => {
  const seedValue =
    timeline.timelineType === "Private" && timeline.userIds.length > 0
      ? timeline.userIds[0].toString()
      : "";
  return (
    <Link
      to={`${Routes.timeline.url}/${timeline.id}`}
      style={{ textDecoration: "none" }}
    >
      <Card
        sx={{
          width: "100%",
          position: "relative",
          height: "120px",
          border: "1px solid #EEEEEE",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.02)",
          transition: "all 0.2s ease-in-out",
          backgroundColor: timeline.unreadCount > 0 ? Colors.gray[50] : "white",

          "&:hover": {
            backgroundColor: Colors.gray[50],
            transform: "translateY(-2px)",
            boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
            borderColor: Colors.gray[300],
          },
        }}
      >
        <CardContent
          sx={{
            height: "100%",
            padding: "16px !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 2 }}>
            <IdenticonAvatar
              seedValue={seedValue}
              profileImageUrl={timeline.profileImageUrl}
              sizeValue={40}
            />
            <Box sx={{ flex: 1 }}>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontWeight: timeline.unreadCount > 0 ? 600 : 500,
                  fontSize: "14px",
                  lineHeight: "1.2",
                  color: Colors.oxford[1100],
                  mb: 0.5,
                }}
              >
                {timeline.name}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Inter",
                  fontSize: "12px",
                  color: Colors.gray[700],
                  lineHeight: "1.2",
                }}
              >
                {timeline.timelineType} Timeline
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 2,
              borderTop: `1px solid ${Colors.gray[200]}`,
              pt: 1,
            }}
          >
            <Typography
              sx={{
                fontFamily: "Inter",
                fontSize: "11px",
                color: Colors.gray[600],
              }}
            >
              {timeline.mostRecentTimelineItemDate
                ? `Last updated: ${new Date(
                    timeline.mostRecentTimelineItemDate
                  ).toLocaleDateString()}`
                : "No updates yet"}
            </Typography>

            {timeline.unreadCount > 0 && (
              <Badge
                badgeContent={timeline.unreadCount}
                color="warning"
                sx={{
                  "& .MuiBadge-badge": {
                    fontSize: "10px",
                    height: "16px",
                    minWidth: "16px",
                    padding: "0 4px",
                  },
                }}
              >
                <MailIcon
                  sx={{
                    color: Colors.gray[600],
                    fontSize: "18px",
                  }}
                />
              </Badge>
            )}
          </Box>
        </CardContent>
      </Card>
    </Link>
  );
};

export { TimelineCard };
