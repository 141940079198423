import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";

interface ActivityCardProps {
  title: string;
  value: number | null;
  target: number | null;
  unit?: string;
  gradient: string;
  overrideText?: string;
}

export const ActivityCard: React.FC<ActivityCardProps> = ({
  title,
  value,
  target,
  unit = "",
  gradient,
  overrideText = "",
}) => {
  if (value === null || target === null) return null;
  const progress = target === 0 ? 0 : Math.min((value / target) * 100, 100);
  const progressDisplayColor = progress === 0 ? 100 : progress;

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "#fff",
        borderRadius: "12px",
        textAlign: "left",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        "&:hover": {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
          cursor: "pointer",
        },
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "bold",
            color: "#333",
            mt: 1,
          }}
        >
          {overrideText || `${value?.toLocaleString()}`}{" "}
          <span style={{ fontSize: "0.8rem" }}>{unit}</span>
        </Typography>
      </Box>
      <Box sx={{ mt: 2 }}>
        <LinearProgress
          variant="determinate"
          value={progressDisplayColor}
          sx={{
            height: "8px",
            borderRadius: "4px",
            backgroundColor: "#e0e0e0",
            "& .MuiLinearProgress-bar": {
              backgroundImage: gradient,
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.5 }}>
          <Typography variant="caption">{0}</Typography>
          <Typography variant="caption">{target}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
