import { FC, useState } from "react";
import { Box } from "@mui/system";
import { Typography, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { useTypedSelector } from "shared/stores";
import { TimelineContent } from "./TimelineContent";
import { CreateTimelineModal } from "./CreateTimelineModal";

const Timeline: FC = () => {
  const { t } = useTranslation();
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const handleToggleModal = () => setIsCreateModalOpen(!isCreateModalOpen);

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box sx={sharedStyles.containers.sidebar.heading}>
        <Typography sx={sharedStyles.headings.sidebar}>
          {t("timeline.title")}
        </Typography>
        <div id="create-timeline">
          <PrimaryButton
            value={t("timeline.create-group-timeline")}
            type="submit"
            variant="contained"
            color="primary"
            onClick={handleToggleModal}
            icon="add_icon"
          />
        </div>
      </Box>
      <TimelineContent />

      <CreateTimelineModal
        isOpenModal={isCreateModalOpen}
        handleCloseModal={handleToggleModal}
      />
    </Box>
  );
};

export { Timeline };
