import React, { FC } from "react";
import dayjs from "dayjs";
import { Box, Typography, Skeleton, Paper, Grid } from "@mui/material";
import {
  useGetAthleteLeaderboardQuery,
  useGetActivePartnerQuery,
  useAddPartnerTrackingLogMutation,
} from "shared/api/healthScores/healthScores";
import { PartnerTrackingLogRequest } from "shared/api";
import { Colors, getGraphicSvg } from "shared/themes";
import { styled } from "@mui/system";
import { LeaderboardScoreCard } from "../Dashboard/AthleteDashboard/LeaderboardScoreCard";

const LeaderboardContainer = styled(Box)({
  padding: "24px",
});

const StatsCard = styled(Paper)({
  backgroundColor: "white",
  border: `1px solid ${Colors.gray[200]}`,
  borderRadius: "16px",
  padding: "24px",
  marginBottom: "16px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
});

const CombinedCard = styled(StatsCard)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px",
  height: "auto",
});

const AthleteLeaderboard: FC = () => {
  const { data: leaderboardData, isLoading } = useGetAthleteLeaderboardQuery(
    {}
  );

  const { data: partnerData } = useGetActivePartnerQuery({});

  const [addPartnerTrackingLog] = useAddPartnerTrackingLogMutation();

  const handleLearnMoreClick = () => {
    try {
      addPartnerTrackingLog({
        partnerId: partnerData?.id,
        partnerTrackingLogType: "click",
      } as PartnerTrackingLogRequest).unwrap();
    } catch {
      console.log("Unable to track partner");
    }

    window.open(partnerData?.url, "_blank");
  };

  const leaderboardPrizeIsEnabled = () => {
    return partnerData?.name && partnerData?.prizeIsActive;
  };

  if (isLoading) {
    return (
      <LeaderboardContainer>
        <Skeleton variant="text" width={200} height={32} />
        <Skeleton variant="text" width={300} height={24} />
        <Skeleton variant="rectangular" height={400} sx={{ mt: 3 }} />
      </LeaderboardContainer>
    );
  }

  return (
    <Box sx={{ height: "88%", overflowY: "auto", mt: 2 }}>
      <LeaderboardContainer>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "100%",
              color: Colors.blue[1500],
            }}
          >
            {dayjs().format("MMMM YYYY")}
          </Typography>
        </Box>

        <Box sx={{ textAlign: "left", mt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LeaderboardScoreCard leaderboardData={leaderboardData} />
            </Grid>
          </Grid>
        </Box>
        {leaderboardPrizeIsEnabled() ? (
          <>
            {leaderboardData?.rank &&
              leaderboardData.rank !== 0 &&
              leaderboardData.rank < 5 && (
                <Box
                  sx={{
                    backgroundColor: Colors.blue[200],
                    padding: "20px",
                    borderRadius: "10px",
                    mt: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    textAlign: "left",
                  }}
                >
                  <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
                    Keep it up!
                  </Typography>
                  <Typography variant="body1">
                    You are among the top 5 athletes in the running to win a
                    prize this month.
                  </Typography>
                </Box>
              )}

            <Grid mt={2}>
              {partnerData?.name && (
                <Grid item xs={12}>
                  <CombinedCard
                    elevation={0}
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ flexGrow: 1, textAlign: "left" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            color: Colors.gray[900],
                            mb: 1,
                            fontWeight: "600",
                          }}
                        >
                          {partnerData?.prize}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: Colors.gray[600],
                            mb: 1,
                          }}
                        >
                          Provided by {partnerData?.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            color: Colors.gray[800],
                          }}
                        >
                          {partnerData?.description}
                        </Typography>
                      </Box>
                      <Box
                        component="img"
                        src={partnerData?.logoUrl}
                        alt="Partner Logo"
                        sx={{ height: "80px", objectFit: "contain", ml: 2 }}
                      />
                    </Box>
                    <Box
                      sx={{
                        mt: 1,
                        textAlign: "left",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          color: Colors.blue[600],
                          cursor: "pointer",
                        }}
                        onClick={handleLearnMoreClick}
                      >
                        Learn more about {partnerData?.name}
                      </Typography>
                    </Box>
                  </CombinedCard>
                </Grid>
              )}
            </Grid>
          </>
        ) : null}
      </LeaderboardContainer>
    </Box>
  );
};

export { AthleteLeaderboard };
