import {
  Box,
  FormControl,
  FormControlLabel,
  styled,
  Button,
  ToggleButton,
  Typography,
  Select,
  Divider,
} from "@mui/material";
import { Colors } from "shared/themes";

export const FilterContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  display: "flex",
  gap: theme.spacing(2),
  alignItems: "center",
  padding: theme.spacing(3),
  backgroundColor: "white",
  borderRadius: theme.spacing(1),
  boxShadow:
    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
}));

export const CalendarContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2),
  boxShadow:
    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  "& .rbc-calendar": {
    fontFamily: "Inter, sans-serif",
  },
  "& .rbc-header": {
    padding: theme.spacing(1),
    fontWeight: 600,
    color: Colors.gray[700],
  },
  "& .rbc-time-header": {
    backgroundColor: Colors.gray[50],
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  "& .rbc-time-content": {
    backgroundColor: "white",
  },
  "& .rbc-event": {
    borderRadius: "6px",
    padding: "4px 8px",
    fontSize: "0.875rem",
  },
  "& .rbc-today": {
    backgroundColor: Colors.blue[100],
  },
}));

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  "& .MuiInputLabel-root": {
    color: Colors.gray[600],
  },
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    "& fieldset": {
      borderColor: Colors.gray[200],
    },
    "&:hover fieldset": {
      borderColor: Colors.gray[300],
    },
    "&.Mui-focused fieldset": {
      borderColor: Colors.blue[500],
    },
  },
}));

export const ViewToggle = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  "& .MuiTypography-root": {
    fontFamily: "Inter",
    fontSize: "14px",
    color: Colors.gray[700],
  },
  "& .MuiSwitch-root": {
    marginRight: theme.spacing(1),
  },
}));

export const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  textTransform: "none",
  fontSize: "13px",
  padding: "8px 16px",
  borderRadius: "4px",
  height: "32px",
  border: `1px solid ${Colors.gray[300]}`,
  minWidth: "100px",
  fontFamily: "Inter",
  color: Colors.gray[700],
  backgroundColor: "white",
  "&.Mui-selected": {
    color: Colors.blue[700],
    backgroundColor: Colors.blue[200],
    "&:hover": {
      backgroundColor: Colors.blue[100],
    },
  },
  "&:hover": {
    backgroundColor: Colors.gray[100],
  },
}));

export const FilterToggleButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  fontSize: "13px",
  padding: "8px 16px",
  borderRadius: "4px",
  height: "32px",
  border: `1px solid ${Colors.gray[300]}`,
  color: Colors.gray[700],
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: Colors.gray[100],
  },
  display: "flex",
  alignItems: "center",
  gap: "6px",
}));

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  backgroundColor: "white",
  borderRadius: theme.spacing(1),
  boxShadow:
    "0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06)",
  padding: theme.spacing(3),
}));

export const ModalHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: theme.spacing(3),
}));

export const ModalTitle = styled(Typography)(({ theme }) => ({
  fontFamily: "Inter",
  fontWeight: 600,
  fontSize: "18px",
  color: Colors.gray[900],
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: "white",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: Colors.gray[300],
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: Colors.gray[400],
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: Colors.blue[500],
  },
  "& .MuiSelect-select": {
    padding: "8px 14px",
    fontSize: "14px",
  },
}));

export const StyledDateInput = styled("input")(({ theme }) => ({
  width: "100%",
  height: "40px",
  padding: "8px 14px",
  borderRadius: "4px",
  border: `1px solid ${Colors.gray[300]}`,
  fontSize: "14px",
  fontFamily: "Inter",
  "&:focus": {
    outline: "none",
    borderColor: Colors.blue[500],
  },
}));

export const StyledTimeInput = styled("input")(({ theme }) => ({
  width: "100%",
  height: "40px",
  padding: "8px 14px",
  borderRadius: "4px",
  border: `1px solid ${Colors.gray[300]}`,
  fontSize: "14px",
  fontFamily: "Inter",
  "&:focus": {
    outline: "none",
    borderColor: Colors.blue[500],
  },
}));

export const StyledCheckboxLabel = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: theme.spacing(1),
  fontSize: "14px",
  color: Colors.gray[700],
  cursor: "pointer",
  "& input": {
    cursor: "pointer",
  },
}));

export const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: `${theme.spacing(2)} 0`,
  backgroundColor: Colors.gray[200],
}));

export const ModalSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

export const SectionTitle = styled(Typography)(({ theme }) => ({
  fontSize: "14px",
  fontWeight: 500,
  color: Colors.gray[700],
  marginBottom: theme.spacing(1),
}));
