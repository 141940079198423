/* eslint-disable no-nested-ternary */
// BookingScheduleListView.tsx

import React, { FC, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  TextField,
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  Avatar,
  IconButton,
  Switch,
} from "@mui/material";
import dayjs from "dayjs";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Booking, BookingGroup } from "shared/api";
import { getGraphicSvg } from "shared/themes";
import { LoadingButton } from "@mui/lab";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { UpdateBookingModal } from "./UpdateBookingModal";
import {
  FilterContainer,
  StyledFormControl,
  ViewToggle,
  FilterToggleButton,
} from "./styles";

interface BookingScheduleListViewProps {
  bookingGroups: BookingGroup[] | undefined;
  bookings: Booking[] | undefined;
  bookingsCount: number | undefined;
  count: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  refetch: () => void;
}

const BookingScheduleListView: FC<BookingScheduleListViewProps> = ({
  bookingGroups,
  bookings,
  bookingsCount,
  count,
  setCount,
  refetch,
}) => {
  const calendarSvg = getGraphicSvg("calendar");

  const handleLoadMoreBookings = () => {
    setCount(count + 10);
    refetch();
  };

  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  // State to keep track of selected group types
  const [selectedGroupTypes, setSelectedGroupTypes] = useState<number[]>([]);

  // State for date filtering
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  // Add state for filters visibility
  const [showFilters, setShowFilters] = useState(false);

  const handleCloseBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const handleGroupTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedGroupTypes(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  // Filter bookings based on selected group types and dates
  const filteredBookings = bookings
    ? bookings.filter((booking) => {
        const bookingDate = dayjs(booking.startDateTime);
        // Check if booking is within date range
        const isWithinDateRange =
          (!startDate || bookingDate.isSameOrAfter(startDate, "day")) &&
          (!endDate || bookingDate.isSameOrBefore(endDate, "day"));
        const matchesGroupType =
          selectedGroupTypes.length === 0 ||
          (booking.bookingGroupId !== null &&
            selectedGroupTypes.includes(booking.bookingGroupId));
        return isWithinDateRange && matchesGroupType;
      })
    : [];

  // Group bookings by date
  const groupBookingsByDate = (bookings: Booking[]) => {
    return bookings.reduce((acc: { [date: string]: Booking[] }, booking) => {
      const dateKey = dayjs(booking.startDateTime).format("YYYY-MM-DD");
      if (!acc[dateKey]) {
        acc[dateKey] = [];
      }
      acc[dateKey].push(booking);
      return acc;
    }, {});
  };

  const groupedBookings = filteredBookings
    ? groupBookingsByDate(filteredBookings)
    : {};

  const getBookingStatusColor = (status: string) => {
    switch (status) {
      case "Full":
        return "error";
      case "Almost Full":
        return "warning";
      default:
        return "textSecondary";
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, mb: 2, alignItems: "center" }}>
        <FilterToggleButton
          onClick={() => setShowFilters(!showFilters)}
          endIcon={
            showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          <SettingsOutlinedIcon sx={{ fontSize: 18 }} />
          Filters
        </FilterToggleButton>
      </Box>

      {showFilters && (
        <FilterContainer>
          <StyledFormControl fullWidth>
            <InputLabel id="group-type-filter-label">
              Filter by Group Type
            </InputLabel>
            <Select
              labelId="group-type-filter-label"
              id="group-type-filter"
              multiple
              value={selectedGroupTypes}
              onChange={handleGroupTypeChange}
              renderValue={(selected) =>
                bookingGroups
                  ?.filter((group) => selected.includes(group.id))
                  .map((group) => group.name)
                  .join(", ") || "All"
              }
            >
              {bookingGroups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  <Checkbox
                    checked={selectedGroupTypes.indexOf(group.id) > -1}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <TextField
            sx={{ width: "100%", height: "56px" }}
            type="date"
            label="Start Date"
            value={startDate ? startDate.format("YYYY-MM-DD") : ""}
            onChange={(e) => setStartDate(dayjs(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            sx={{ width: "100%", height: "56px" }}
            type="date"
            label="End Date"
            value={endDate ? endDate.format("YYYY-MM-DD") : ""}
            onChange={(e) => setEndDate(dayjs(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FilterContainer>
      )}

      {filteredBookings && filteredBookings.length > 0 ? (
        <>
          {Object.keys(groupedBookings).map((date) => (
            <Card key={date} sx={{ mb: 4 }}>
              <CardHeader
                sx={{ backgroundColor: "#FAFAFA" }}
                title={
                  <Typography variant="subtitle1" fontWeight="bold">
                    {dayjs(date).format("dddd, D MMMM YYYY")}
                  </Typography>
                }
              />
              <Divider />
              <CardContent sx={{ padding: 0 }}>
                <List>
                  {groupedBookings[date]
                    .sort(
                      (a, b) =>
                        dayjs(a.startDateTime).unix() -
                        dayjs(b.startDateTime).unix()
                    )
                    .map((booking) => {
                      const athleteCount =
                        booking.athleteAttendees?.length || 0;
                      const maxAttendees = booking.maxAttendees ?? 0;

                      const availableSpots = maxAttendees - athleteCount;
                      let bookingStatus = "";

                      // If Private booking, we don't show the status
                      if (availableSpots === 0) {
                        bookingStatus = "Full";
                      } else if (availableSpots <= maxAttendees * 0.25) {
                        bookingStatus = `Almost Full - ${availableSpots} spots left`;
                      } else {
                        bookingStatus = `${availableSpots} of ${maxAttendees} spots left`;
                      }

                      return (
                        <ListItem
                          key={booking.id}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            paddingY: 2,
                            borderBottom: "1px solid #e0e0e0",
                          }}
                        >
                          {/* Booking Details */}
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {/* Left side with time and booking details */}
                            <Box display="flex" alignItems="center">
                              <Avatar
                                sx={{ bgcolor: "#7e57c2", marginRight: 2 }}
                              >
                                {booking.name.charAt(0)}
                              </Avatar>
                              <Box>
                                <Typography variant="body1" fontWeight="bold">
                                  {dayjs(booking.startDateTime).format(
                                    "hh:mm A"
                                  )}{" "}
                                  -{" "}
                                  {dayjs(booking.endDateTime).format("hh:mm A")}
                                </Typography>
                                <Typography variant="body2">
                                  {booking.name} -{" "}
                                  {booking.privateBooking
                                    ? "Private"
                                    : "Public"}
                                </Typography>
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {booking.description}
                                </Typography>
                                {!booking.privateBooking && (
                                  <Typography
                                    variant="subtitle2"
                                    color={getBookingStatusColor(bookingStatus)}
                                  >
                                    Status: {bookingStatus}
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                            {/* Right side with "Details" icon */}
                            <Box display="flex" alignItems="center">
                              <IconButton
                                edge="end"
                                onClick={() => {
                                  setSelectedBooking(booking);
                                  setIsBookingModalOpen(true);
                                }}
                              >
                                <ArrowForwardIosIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>

                          {/* Display Attendees */}
                          <Box mt={2} sx={{ width: "100%" }}>
                            {/* Coaches */}
                            {booking.coachAttendees &&
                              booking.coachAttendees.length > 0 && (
                                <Box display="flex" alignItems="center" mb={1}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ marginRight: 1 }}
                                  >
                                    Coaches
                                  </Typography>
                                  {booking.coachAttendees?.map((attendee) => (
                                    <IdenticonAvatar
                                      key={attendee.id}
                                      seedValue={attendee.id.toString()}
                                      profileImageUrl={attendee.profileImageUrl}
                                      sizeValue={30}
                                      userName={attendee.firstName}
                                    />
                                  ))}
                                </Box>
                              )}

                            {/* Athletes */}
                            {booking.athleteAttendees &&
                              booking.athleteAttendees.length > 0 && (
                                <Box display="flex" alignItems="center">
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ marginRight: 1 }}
                                  >
                                    Athletes
                                  </Typography>
                                  {booking.athleteAttendees?.map((attendee) => (
                                    <IdenticonAvatar
                                      key={attendee.id}
                                      seedValue={attendee.id.toString()}
                                      profileImageUrl={attendee.profileImageUrl}
                                      sizeValue={30}
                                      userName={attendee.firstName}
                                    />
                                  ))}
                                </Box>
                              )}
                          </Box>
                        </ListItem>
                      );
                    })}
                </List>
              </CardContent>
            </Card>
          ))}
          {bookingsCount && count < bookingsCount ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: 2,
                marginBottom: 4,
              }}
            >
              <LoadingButton
                onClick={handleLoadMoreBookings}
                sx={{
                  textTransform: "none",
                }}
                loading={false}
              >
                Load more bookings
              </LoadingButton>
            </Box>
          ) : null}
          {/* End of the list message */}
          <Box sx={{ textAlign: "center", paddingY: 2 }}>
            <Typography variant="body2" color="textSecondary">
              You have reached the end of your schedule.
            </Typography>
          </Box>
        </>
      ) : (
        <Box textAlign="center" mt={5}>
          <Box
            component="img"
            src={calendarSvg}
            alt="Calendar no data"
            sx={{ height: "125px", mb: 4 }}
          />
          <Typography variant="h6" color="textSecondary">
            There are no bookings in your schedule.
          </Typography>
        </Box>
      )}
      <UpdateBookingModal
        isOpenModal={isBookingModalOpen}
        handleCloseModal={handleCloseBookingModal}
        booking={selectedBooking}
        setBooking={setSelectedBooking}
      />
    </Box>
  );
};

export { BookingScheduleListView };
