import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery, Box, Typography } from "@mui/material";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { PrimaryButton } from "components/Form/PrimaryButton";
import { CreateExerciseModal } from "components/Modals/CreateExerciseModal";
import { ExerciseLibraryContent } from "./ExerciseLibraryContent";

const ExerciseLibrary: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;
  const [isOpenModal, setIsOpenModal] = useState(false);
  const handleToggleModal = () => setIsOpenModal(!isOpenModal);

  const { t } = useTranslation();

  return (
    <Box
      sx={
        showSidebar
          ? sharedStyles.containers.sidebar.content
          : sharedStyles.containers.sidebar.contentFullscreen
      }
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box sx={sharedStyles.containers.sidebar.heading}>
          <Typography sx={sharedStyles.headings.sidebar}>
            Exercise library
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <PrimaryButton
              value={t("exercise-library.add-btn")}
              type="submit"
              variant="contained"
              color="primary"
              onClick={handleToggleModal}
              icon="add_icon"
            />
          </Box>
        </Box>
      </Box>
      <ExerciseLibraryContent />

      <CreateExerciseModal
        handleCloseModal={handleToggleModal}
        isOpenModal={isOpenModal}
      />
    </Box>
  );
};

export { ExerciseLibrary };
