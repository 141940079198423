/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC, useState } from "react";
import { Alert, Button, Typography, useMediaQuery } from "@mui/material";
import { Timeline } from "shared/api";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Colors } from "shared/themes";
import EditIcon from "@mui/icons-material/Edit";
import { CreateTimelineModal } from "./AdminTimeline/CreateTimelineModal";

interface TimelineViewHeadingProps {
  timelineData?: Timeline;
}

const TimelineViewHeading: FC<TimelineViewHeadingProps> = ({
  timelineData,
}) => {
  const seedValue =
    timelineData?.timelineType === "Private" && timelineData.userIds.length > 0
      ? timelineData.userIds[0].toString()
      : "";
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  return (
    <div style={{ display: "flex", gap: "16px" }}>
      <IdenticonAvatar
        seedValue={seedValue}
        sizeValue={50}
        profileImageUrl={timelineData?.profileImageUrl}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "22px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {timelineData?.name}
        </Typography>
        <Typography
          sx={{
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: "400",
            fontSize: "14px",
            lineHeight: "18px",
            letterSpacing: "0.16px",
            color: `${Colors.oxford[1100]}`,
          }}
        >
          {timelineData?.timelineType} Timeline
        </Typography>
        {timelineData?.timelineType === "Group" && (
          <Button
            onClick={() => setIsEditModalOpen(true)}
            variant="text"
            size="small"
            color="primary"
            startIcon={<EditIcon />}
            sx={{
              textTransform: "none",
              textDecoration: "none",
              width: "fit-content",
            }}
          >
            Edit
          </Button>
        )}
      </div>
      <CreateTimelineModal
        isOpenModal={isEditModalOpen}
        handleCloseModal={() => setIsEditModalOpen(false)}
        editMode
        timelineData={timelineData}
      />
    </div>
  );
};

export { TimelineViewHeading };
