import { FC } from "react";
import { Box, Typography, Skeleton, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useGetTimelinesQuery } from "shared/api";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import { Routes } from "shared/routers/Routes";
import { TimelineCard } from "../TimelineCard";

const AthleteTimelineSelector: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: timelines, isLoading } = useGetTimelinesQuery({});

  const handleTimelineSelect = (timelineId: number) => {
    navigate(`${Routes.athleteTimeline.url}/${timelineId}`);
  };

  if (isLoading) {
    return (
      <Box sx={{ padding: "24px 24px 23px 24px" }}>
        <Box sx={{ mb: 2 }}>
          <Skeleton variant="text" width={140} height={24} />
          <Skeleton variant="text" width={200} height={32} />
        </Box>
        <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={120} sx={{ mb: 2 }} />
        <Skeleton variant="rectangular" height={120} />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "88%", overflowY: "auto" }}>
      <Box sx={{ padding: "24px 24px 23px 24px" }}>
        <Box sx={{ mb: 3 }}>
          <Typography sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}>
            {t("timeline.your-timelines")}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "24px",
              lineHeight: "100%",
              color: Colors.blue[1500],
              mb: 1,
            }}
          >
            {t("timeline.select-timeline")}
          </Typography>
        </Box>

        <Grid container spacing={2} direction="column">
          {timelines?.map((timeline) => (
            <Grid item key={timeline.id}>
              <Box onClick={() => handleTimelineSelect(timeline.id)}>
                <TimelineCard timeline={timeline} />
              </Box>
            </Grid>
          ))}
        </Grid>

        {timelines?.length === 0 && (
          <Typography
            sx={{
              textAlign: "center",
              color: "rgba(0, 0, 0, 0.6)",
              mt: 4,
            }}
          >
            {t("timeline.no-timelines")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export { AthleteTimelineSelector };
