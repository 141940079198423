import { Routes } from "../../../../routers";

export const navData = [
  {
    id: 1,
    title: "Dashboard",
    content: "dashboard",
    icon: "home_outlined",
    link: `${Routes.dashboard.url}`,
    adminOnly: false,
    physioOnly: false,
    navId: "dashboard-nav",
  },
  {
    id: 2,
    title: "Clients",
    content: "clients",
    icon: "account_circle",
    link: `${Routes.clients.url}`,
    adminOnly: false,
    physioOnly: false,
    navId: "clients-nav",
  },
  {
    id: 3,
    title: "Treatment notes",
    content: "user notes",
    icon: "summarize",
    link: `${Routes.userNotes.url}`,
    adminOnly: false,
    physioOnly: true,
    navId: "userNotes-nav",
  },
  {
    id: 4,
    title: "Programs",
    content: "programs",
    icon: "create_new_folder_outlined_icon",
    link: `${Routes.programs.url}`,
    adminOnly: false,
    physioOnly: false,
    navId: "programs-nav",
  },
  {
    id: 5,
    title: "Nutrition",
    content: "nutrition",
    icon: "restaurant_menu",
    link: `${Routes.nutrition.url}`,
    adminOnly: false,
    physioOnly: false,
    navId: "nutrition-nav",
  },
  {
    id: 6,
    title: "Leaderboard",
    content: "leaderboard",
    icon: "leaderboard",
    link: `${Routes.leaderboard.url}`,
    adminOnly: true,
    physioOnly: false,
    navId: "leaderboard-nav",
  },
  {
    id: 7,
    title: "Bookings",
    content: "bookings",
    icon: "calendar_today",
    link: `${Routes.bookings.url}`,
    adminOnly: false,
    physioOnly: false,
    navId: "bookings-nav",
  },
  {
    id: 8,
    title: "Exercises",
    content: "exercise_library",
    icon: "fitness_center",
    link: `${Routes.exerciseLibrary.url}`,
    adminOnly: false,
    physioOnly: false,
  },
  {
    id: 9,
    title: "Live training",
    content: "live training",
    icon: "live_tv",
    link: `${Routes.liveTraining.url}`,
    adminOnly: false,
    physioOnly: false,
  },
  {
    id: 10,
    title: "Timelines",
    content: "timeline",
    icon: "message",
    link: `${Routes.timeline.url}`,
    adminOnly: false,
    physioOnly: false,
  },
  {
    id: 11,
    title: "Billing",
    content: "billing",
    icon: "money",
    link: `${Routes.billing.url}`,
    adminOnly: false,
    physioOnly: false,
  },
];
