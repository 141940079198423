import { Capacitor } from "@capacitor/core";
import {
  Sahha,
  SahhaEnvironment,
  SahhaSensor,
  SahhaSensorStatus,
} from "sahha-capacitor";

const STANDARD_SENSORS = [
  SahhaSensor.sleep,
  SahhaSensor.exercise,
  SahhaSensor.step_count,
  SahhaSensor.floor_count,
  SahhaSensor.active_energy_burned,
  SahhaSensor.heart_rate,
  SahhaSensor.resting_heart_rate,
];

const IOS_REQUIRED_SENSORS = [
  SahhaSensor.stand_time,
  SahhaSensor.move_time,
  SahhaSensor.exercise_time,
  SahhaSensor.activity_summary,
  SahhaSensor.heart_rate_variability_sdnn,
];

const ANDROID_REQUIRED_SENSORS = [SahhaSensor.heart_rate_variability_rmssd];

class SahhaService {
  private static instance: SahhaService;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  public static getInstance(): SahhaService {
    if (!SahhaService.instance) {
      SahhaService.instance = new SahhaService();
    }
    return SahhaService.instance;
  }

  // eslint-disable-next-line class-methods-use-this, consistent-return
  async configureSahha(): Promise<void | Error> {
    try {
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      await Sahha.configure({
        settings: {
          environment: SahhaEnvironment.production,
          sensors: REQUIRED_SENSORS,
        },
      });
      console.log("Sahha configured successfully.");
    } catch (error) {
      console.error("Error configuring Sahha:", error);
      return new Error("Failed to configure Sahha");
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getSahhaSensorStatus(): Promise<boolean> {
    try {
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      const sensorStatus = await Sahha.getSensorStatus({
        sensors: REQUIRED_SENSORS,
      });
      return sensorStatus.status === SahhaSensorStatus.enabled;
    } catch (error) {
      console.error("Error getting Sahha sensor status:", error);
      return false;
    }
  }

  /**
   * Initializes Sahha, checks authentication, logs in if necessary, and handles permissions.
   */
  async initSahha(profileToken: string, refreshToken: string) {
    try {
      const authStatus = await Sahha.isAuthenticated();
      if (!authStatus.success) {
        await this.login(profileToken, refreshToken);
      } else {
        console.log("Sahha already authenticated");
      }

      await this.handlePermissions();
    } catch (error) {
      console.error("Error initializing Sahha:", error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async deauthenticate(): Promise<boolean> {
    try {
      return (await Sahha.deauthenticate()).success;
    } catch (error) {
      console.error("Error deauthenticating Sahha status:", error);
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private async login(profileToken: string, refreshToken: string) {
    try {
      const response = await Sahha.authenticateToken({
        profileToken,
        refreshToken,
      });
      console.log("Sahha authentication response:", response);
    } catch (error) {
      console.error("Error during Sahha authentication:", error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async handlePermissions() {
    try {
      console.log("Handling permissions");
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      const sensorStatus = await Sahha.getSensorStatus({
        sensors: REQUIRED_SENSORS,
      });
      console.log("Sensor Status:", SahhaSensorStatus[sensorStatus.status]);

      if (sensorStatus.status !== SahhaSensorStatus.enabled) {
        const enableResponse = await Sahha.enableSensors({
          sensors: REQUIRED_SENSORS,
        });
        console.log(
          "Enable Sensors Response:",
          SahhaSensorStatus[enableResponse.status]
        );
        if (enableResponse.status !== SahhaSensorStatus.enabled) {
          await Sahha.openAppSettings();
        }
      } else {
        const platform = Capacitor.getPlatform();
        if (platform === "ios") {
          return { enabled: true };
        }
      }
      return { enabled: false };
    } catch (error) {
      console.error("Error handling permissions:", error);
      return { enabled: false };
    }
  }

  /**
   * Posts user demographic information to Sahha.
   * @param gender User's gender.
   * @param age User's age.
   */
  static async postDemographic(gender: string, age: number) {
    try {
      const response = await Sahha.postDemographic({
        demographic: {
          gender: gender !== "" ? gender : undefined,
          age: age !== null ? age : undefined,
        },
      });
      console.log("Post Demographic Response:", response);
    } catch (error) {
      console.error("Error posting demographic data:", error);
    }
  }

  /**
   * Retrieves user demographic information from Sahha.
   */
  static async getDemographic() {
    try {
      const response = await Sahha.getDemographic();
      console.log("Demographic Data:", response.demographic);
    } catch (error) {
      console.error("Error getting demographic data:", error);
    }
  }

  /**
   * Opens the app settings.
   */
  static async openAppSettings() {
    try {
      await Sahha.openAppSettings();
    } catch (error) {
      console.error("Error opening app settings:", error);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async getSensorStatus(): Promise<string> {
    try {
      const REQUIRED_SENSORS = await this.getRequiredSensors();
      const sensorStatus = await Sahha.getSensorStatus({
        sensors: REQUIRED_SENSORS,
      });
      console.log("Sensor Status:", SahhaSensorStatus[sensorStatus.status]);
      if (sensorStatus.status === SahhaSensorStatus.enabled) {
        return "enabled";
      }
      if (sensorStatus.status === SahhaSensorStatus.disabled) {
        return "disabled";
      }
      if (sensorStatus.status === SahhaSensorStatus.unavailable) {
        return "unavailable";
      }
      return "pending";
    } catch (error) {
      console.error("Error getting sensor status:", error);
      return "unavailable";
    }
  }

  // eslint-disable-next-line class-methods-use-this
  private async getRequiredSensors() {
    const platform = Capacitor.getPlatform();
    if (platform === "ios") {
      return [...STANDARD_SENSORS, ...IOS_REQUIRED_SENSORS];
    }
    return [...STANDARD_SENSORS, ...ANDROID_REQUIRED_SENSORS];
  }
}

export default SahhaService;
