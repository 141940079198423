import React from "react";
import { Box, Typography, LinearProgress } from "@mui/material";
import { formatDuration } from "./formatters";

interface SleepCardProps {
  sleepDuration: number | null;
  sleepDurationGoal: number | null;
  gradient: string;
}

export const SleepCard: React.FC<SleepCardProps> = ({
  sleepDuration,
  sleepDurationGoal,
  gradient,
}) => {
  if (
    sleepDuration === null ||
    sleepDurationGoal === null ||
    sleepDurationGoal === 0
  )
    return null;

  const progress = Math.min((sleepDuration / sleepDurationGoal) * 100, 100);
  const formattedSleepDuration = formatDuration(sleepDuration);
  const formattedSleepDurationGoal = formatDuration(sleepDurationGoal);

  return (
    <Box
      sx={{
        padding: "16px",
        backgroundColor: "white",
        borderRadius: "12px",
        textAlign: "left",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
        "&:hover": {
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        },
      }}
    >
      <Typography sx={{ fontSize: "0.9rem", color: "rgba(0, 0, 0, 0.6)" }}>
        Sleep
      </Typography>
      <Typography
        sx={{
          fontSize: "1.7rem",
          fontWeight: "bold",
          color: "#333",
          mt: 1,
          mb: "auto",
        }}
      >
        {formattedSleepDuration}
      </Typography>
      <Box sx={{ mt: 1 }}>
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{
            height: "8px",
            borderRadius: "4px",
            backgroundColor: "white",
            "& .MuiLinearProgress-bar": {
              backgroundImage: gradient,
            },
          }}
        />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 0.5 }}>
          <Typography variant="caption"> </Typography>
          <Typography variant="caption">
            {formattedSleepDurationGoal}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
