import { FC } from "react";
import { Box } from "@mui/system";
import { Icon, Modal, Typography, Alert, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { Colors } from "shared/themes";
import {
  Timeline,
  useCreateTimelineMutation,
  ClientResponse,
  useUpdateTimelineMutation,
} from "shared/api";
import { MultiClientSelector } from "components/ClientSelector/MultiClientSelector";
import { Routes } from "shared/routers";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

interface CreateTimelineModalProps {
  isOpenModal: boolean;
  handleCloseModal: () => void;
  editMode?: boolean;
  timelineData?: Timeline;
}

const CreateTimelineModal: FC<CreateTimelineModalProps> = ({
  isOpenModal,
  handleCloseModal,
  editMode = false,
  timelineData,
}) => {
  const { t } = useTranslation();
  const [createTimeline, { isLoading: isCreateLoading }] =
    useCreateTimelineMutation();
  const [updateTimeline, { isLoading: isUpdateLoading }] =
    useUpdateTimelineMutation();
  const navigate = useNavigate();

  const isLoading = isCreateLoading || isUpdateLoading;

  const validationSchema = Yup.object({
    name: Yup.string()
      .required(t("timeline-modal-validations.name-required"))
      .min(3, t("timeline-modal-validations.name-min-length")),
    selectedClients: Yup.array().min(
      2,
      t("timeline-modal-validations.min-clients-required")
    ),
  });

  return (
    <Modal open={isOpenModal} onClose={handleCloseModal}>
      <Box
        sx={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "400px",
            background: Colors.gray[100],
            borderRadius: "4px",
            padding: "16px 24px 24px",
            boxShadow:
              "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 2,
            }}
          >
            <Typography
              variant="h5"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                color: Colors.blue[1300],
              }}
            >
              {editMode
                ? t("timeline.edit-group-timeline")
                : t("timeline.create-group-timeline")}
            </Typography>
            <Icon
              onClick={handleCloseModal}
              sx={{ color: Colors.gray[1400], cursor: "pointer" }}
            >
              close_icon
            </Icon>
          </Box>

          <Typography
            sx={{
              fontSize: "14px",
              color: Colors.blue[1300],
              mb: 3,
            }}
          >
            {t("timeline-modal.content")}
          </Typography>

          <Formik
            initialValues={{
              name: timelineData?.name || "",
              selectedClients: timelineData?.athleteMetadata
                ? timelineData.athleteMetadata.map((athlete) => ({
                    id: athlete.id,
                    firstName: athlete.firstName,
                    lastName: athlete.lastName,
                    profileImageUrl: athlete.profileImageUrl,
                  }))
                : ([] as ClientResponse[]),
            }}
            validationSchema={validationSchema}
            onSubmit={async (values, { setStatus, resetForm }) => {
              try {
                if (editMode && timelineData) {
                  await updateTimeline({
                    id: timelineData.id,
                    name: values.name.trim(),
                    userIds: values.selectedClients.map((client) => client.id),
                  }).unwrap();

                  setStatus({
                    success: t("timeline-modal.timeline-success-updated"),
                  });

                  setTimeout(() => {
                    handleCloseModal();
                  }, 1000);
                } else {
                  const createdTimeline = await createTimeline({
                    name: values.name.trim(),
                    userIds: values.selectedClients.map((client) => client.id),
                  }).unwrap();

                  setStatus({
                    success: t("timeline-modal.timeline-success-created"),
                  });
                  setTimeout(() => {
                    navigate(`${Routes.timeline.url}/${createdTimeline.id}`);
                  }, 1500);
                }

                if (!editMode) {
                  resetForm();
                }
              } catch (error: any) {
                setStatus({
                  error: error?.data?.message || t("errors.server-unable"),
                });
              }
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              setFieldValue,
              status,
            }) => (
              <Form>
                <TextField
                  fullWidth
                  name="name"
                  label={t("timeline-modal.name-label")}
                  value={values.name}
                  onChange={handleChange}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                  margin="normal"
                  sx={{ mb: 2 }}
                />

                <MultiClientSelector
                  selectedClients={values.selectedClients as ClientResponse[]}
                  onClientsChange={(clients) =>
                    setFieldValue("selectedClients", clients)
                  }
                  label="Client search"
                  placeholder="Search to add multiple clients..."
                />

                <LoadingButton
                  fullWidth
                  size="large"
                  loading={isLoading}
                  variant="contained"
                  type="submit"
                  disabled={
                    isLoading ||
                    values.selectedClients.length < 2 ||
                    !values.name.trim()
                  }
                  sx={{
                    background: Colors.blue[1200],
                    textTransform: "none",
                    mt: 3,
                  }}
                >
                  {editMode
                    ? t("timeline-modal.update-button")
                    : t("timeline-modal.create-button")}
                </LoadingButton>

                {status?.error && (
                  <Alert sx={{ mt: 2 }} variant="outlined" severity="error">
                    {status.error}
                  </Alert>
                )}
                {status?.success && (
                  <Alert sx={{ mt: 2 }} variant="outlined" severity="success">
                    {status.success}
                  </Alert>
                )}
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
};

export { CreateTimelineModal };
