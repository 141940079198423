import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes as RoutesDom,
  Route,
  useParams,
} from "react-router-dom";
import {
  AdminDashboard,
  AthleteDashboard,
  WorkoutDetails,
  SignIn,
  FitnessProfessionalSignup,
  FitnessProfessionalSignupEmailVerification,
  ClientSignupSuccess,
  ClientSignupEmailVerification,
  ResetPasswordEmailInput,
  ResetPasswordVerification,
  ResetPasswordSuccess,
  ExerciseView,
  Timeline,
  AthleteTimelineView,
  AdminTimelineView,
  WorkoutProgramBuilderSuccessScreen,
  AthletePrograms,
  AdminPrograms,
  ViewPrograms,
  TrainingInsights,
  AthleteTrainingInsights,
  LiveTraining,
  UserProfile,
  OrganisationManagement,
  OrganisationIntegrationSetupSuccess,
  AthleteUserProfile,
  FitnessProfessionalSignupSuccess,
  Users,
  Layout,
  Workflows,
  IndividualWorkflow,
  LeadsContainer,
  IndividualLead,
  BillingContainer,
  UserNoteView,
  OrganisationUserNotesTable,
  ExerciseLibrary,
  ModernProgramBuilder,
  AthleteProgramWorkouts,
  AthleteWorkouts,
  BookingsContainer,
  AthleteBookingSchedule,
  NutritionPlans,
  NutritionPlanView,
  AthleteNutrition,
  AthleteNutritionView,
  AthleteTimelineSelector,
  AdminLeaderboard,
  AthleteLeaderboard,
} from "pages";
import { useAnalytics, useGetUser } from "shared/hooks";
import { useTypedDispatch, useTypedSelector } from "../stores";
import { userSelectors } from "../stores/user";
import { setSidebarState, toggleSidebarState } from "../stores/sidebar/slice";
import {
  ProtectedRoute,
  ProtectedAdminRoute,
  ProtectedAdminOrCoachRoute,
} from "./ProtectedRoute";
import { Routes } from "./Routes";
import { isDev } from "../helpers";

export const Router: React.FC = () => {
  const { startSession } = useAnalytics();
  useGetUser();
  startSession();

  const isAdminOrCoach = useTypedSelector(userSelectors.isAdminOrCoach);
  const isAdmin = useTypedSelector(userSelectors.isAdmin);
  const userId = useTypedSelector(userSelectors.userId);
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const dispatch = useTypedDispatch();

  const openSidebar = () => {
    dispatch(setSidebarState(true));
  };

  const closeSidebar = () => {
    dispatch(setSidebarState(false));
  };

  const toggleSidebar = () => {
    dispatch(toggleSidebarState());
  };

  useEffect(() => {
    if (isAdminOrCoach && !isDev) {
      // eslint-disable-next-line import/extensions
      import("../../usetiful.js")
        .then((module) => {
          module.default(process.env.REACT_APP_USETIFUL_PRODUCT_ID);
        })
        .catch((error) => {
          console.log("Usetiful script failed to load: ", error);
        });
    }
  }, [isAdminOrCoach]);

  useEffect(() => {
    if (userId && !isDev) {
      // eslint-disable-next-line import/extensions
      import("../../beamer.js")
        .then((module) => {
          module.default(process.env.REACT_APP_GET_BEAMER_PRODUCT_ID);
        })
        .catch((error) => {
          console.log("Beamer script failed to load: ", error);
        });
    }
  }, [userId]);

  const [createWorkoutUserId, setCreateWorkoutUserId] = useState<number | null>(
    null
  );
  const getUserIdForWorkoutCreation = (userId: number) =>
    setCreateWorkoutUserId(userId);

  return (
    <BrowserRouter>
      <RoutesDom>
        <Route path={Routes.signIn.url} element={<SignIn />} />
        <Route
          path={Routes.fitnessProfessionalSignup.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.termsOfService.url}
          element={<FitnessProfessionalSignup />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupEmailVerification.url}
          element={<FitnessProfessionalSignupEmailVerification />}
        />
        <Route
          path={Routes.fitnessProfessionalSignupSuccess.url}
          element={<FitnessProfessionalSignupSuccess />}
        />
        <Route
          path={Routes.clientSignupEmailVerification.url}
          element={<ClientSignupEmailVerification />}
        />
        <Route
          path={Routes.clientSignupSuccess.url}
          element={<ClientSignupSuccess />}
        />
        <Route
          path={Routes.forgotPassword.url}
          element={<ResetPasswordEmailInput />}
        />
        <Route
          path={Routes.forgotPasswordVerification.url}
          element={<ResetPasswordVerification />}
        />
        <Route
          path={Routes.forgotPasswordSuccess.url}
          element={<ResetPasswordSuccess />}
        />
        <Route
          path={Routes.dashboard.url}
          element={
            <ProtectedRoute>
              {isAdminOrCoach ? (
                <AdminDashboard
                  closeSidebar={closeSidebar}
                  isOpenSidebar={isOpenSidebar}
                  toggleSidebar={toggleSidebar}
                  openSidebar={openSidebar}
                  getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                />
              ) : (
                <Layout
                  isOpenSidebar={isOpenSidebar}
                  closeSidebar={closeSidebar}
                  openSidebar={openSidebar}
                  toggleSidebar={toggleSidebar}
                >
                  <AthleteDashboard />
                </Layout>
              )}
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.programs.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <AdminPrograms
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                    setCreateWorkoutUserId={setCreateWorkoutUserId}
                  />
                ) : (
                  <AthletePrograms />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.programs.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <ViewPrograms
                    openSidebar={openSidebar}
                    getUserIdForWorkoutCreation={getUserIdForWorkoutCreation}
                  />
                ) : (
                  <AthleteProgramWorkouts />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteWorkouts />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workouts.url}/:userId/:completed?`}
          element={
            <ProtectedRoute>
              <WorkoutDetails />
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.profile.url}/:userId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach ? (
                  <UserProfile closeSidebar={closeSidebar} />
                ) : (
                  <AthleteUserProfile />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.clients.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Users openSidebar={openSidebar} />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.exerciseLibrary.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseLibrary />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.exercise.url}/:exerciseId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <ExerciseView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <Timeline />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={Routes.athleteTimeline.url}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteTimelineSelector />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteTimeline.url}/:timelineId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && (
                  <AthleteTimelineView closeSidebar={closeSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteBookings.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && (
                  <AthleteBookingSchedule closeSidebar={closeSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.timeline.url}/:timelineId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <AdminTimelineView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.modernWorkoutProgramBuilder.url}/:workoutProgramId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <ModernProgramBuilder />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workoutProgramBuilderSuccess.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <WorkoutProgramBuilderSuccessScreen openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.organisation.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdmin && (
                  <OrganisationManagement openSidebar={openSidebar} />
                )}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.organisationIntegrationSetupSuccess.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationIntegrationSetupSuccess
                  openSidebar={openSidebar}
                />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <Workflows />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.workflows.url}/:workflowId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualWorkflow />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.liveTraining.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <LiveTraining />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.trainingInsights.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <TrainingInsights openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.athleteTrainingInsights.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteTrainingInsights />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}`}
          element={
            <ProtectedAdminOrCoachRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <LeadsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminOrCoachRoute>
          }
        />
        <Route
          path={`${Routes.leads.url}/:id`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <IndividualLead />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.billing.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BillingContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.bookings.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <BookingsContainer openSidebar={openSidebar} />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <OrganisationUserNotesTable />
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.userNotes.url}/:userNoteId/:tabId?`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                <UserNoteView />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlans />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.nutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <NutritionPlanView />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteNutrition />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.athleteNutrition.url}/:nutritionPlanId`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteNutritionView />}
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path={`${Routes.leaderboard.url}`}
          element={
            <ProtectedAdminRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {isAdminOrCoach && <AdminLeaderboard />}
              </Layout>
            </ProtectedAdminRoute>
          }
        />
        <Route
          path={`${Routes.athleteLeaderboard.url}`}
          element={
            <ProtectedRoute>
              <Layout
                isOpenSidebar={isOpenSidebar}
                closeSidebar={closeSidebar}
                openSidebar={openSidebar}
                toggleSidebar={toggleSidebar}
              >
                {!isAdminOrCoach && <AthleteLeaderboard />}
              </Layout>
            </ProtectedRoute>
          }
        />
      </RoutesDom>
    </BrowserRouter>
  );
};
