// BookingScheduleCalendarView.tsx

import React, { FC, useState } from "react";
import {
  Box,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Tooltip,
  Switch,
  TextField,
} from "@mui/material";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { Calendar, dayjsLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Booking, BookingGroup, ClientResponse } from "shared/api";
import { UpdateBookingModal } from "./UpdateBookingModal";
import {
  CalendarContainer,
  FilterContainer,
  StyledFormControl,
  ViewToggle,
  FilterToggleButton,
} from "./styles";

dayjs.extend(utc);
dayjs.extend(timezone);

interface BookingScheduleCalendarViewProps {
  bookingGroups: BookingGroup[] | undefined;
  bookings: Booking[] | undefined;
  setBookingsStartDate: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setBookingsEndDate: React.Dispatch<React.SetStateAction<string | undefined>>;
  nonAthleteUsers: ClientResponse[] | undefined;
}

// Add this interface for better type safety
interface CalendarEvent {
  title: string;
  start: Date;
  end: Date;
  resource: Booking;
  tooltipContent?: string; // Add this field for full names
}

// Update the EventComponent
const EventComponent = ({ event }: { event: CalendarEvent }) => (
  <Tooltip title={event.tooltipContent || event.title} arrow placement="top">
    <div style={{ height: "100%", width: "100%" }}>{event.title}</div>
  </Tooltip>
);

const BookingScheduleCalendarView: FC<BookingScheduleCalendarViewProps> = ({
  bookingGroups,
  bookings,
  setBookingsStartDate,
  setBookingsEndDate,
  nonAthleteUsers,
}) => {
  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);

  // State to keep track of selected group types
  const [selectedGroupTypes, setSelectedGroupTypes] = useState<number[]>([]);

  const [selectedCoaches, setSelectedCoaches] = useState<number[]>([]);

  // State for date filtering
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(null);
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(null);

  // Add state for the toggle
  const [showAthleteNames, setShowAthleteNames] = useState(true);

  // Add new state for filters visibility
  const [showFilters, setShowFilters] = useState(false);

  const handleLoadMoreBookings = (newDate: dayjs.Dayjs) => {
    // Get the previous Sunday and next Saturday
    const prevSunday = newDate.startOf("week");
    const nextSaturday = newDate.endOf("week");

    // Update the booking date range
    setBookingsStartDate(prevSunday.format("YYYY-MM-DD"));
    setBookingsEndDate(nextSaturday.format("YYYY-MM-DD"));
  };

  const handleCloseBookingModal = () => {
    setIsBookingModalOpen(false);
  };

  const handleGroupTypeChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedGroupTypes(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  const handleCoachChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setSelectedCoaches(
      typeof value === "string" ? value.split(",").map(Number) : value
    );
  };

  // Filter bookings based on selected group types, coaches, and dates
  const filteredBookings = bookings
    ? bookings.filter((booking) => {
        const bookingDate = dayjs(booking.startDateTime);
        // Check if booking is within date range
        const isWithinDateRange =
          (!startDate || bookingDate.isSameOrAfter(startDate, "day")) &&
          (!endDate || bookingDate.isSameOrBefore(endDate, "day"));
        const matchesGroupType =
          selectedGroupTypes.length === 0 ||
          (booking.bookingGroupId !== null &&
            selectedGroupTypes.includes(booking.bookingGroupId));
        const matchesCoach =
          selectedCoaches.length === 0 ||
          (booking.coachAttendees &&
            booking.coachAttendees.some((coach) =>
              selectedCoaches.includes(coach.id)
            ));

        return isWithinDateRange && matchesGroupType && matchesCoach;
      })
    : [];

  // Prepare events for react-big-calendar
  const events = filteredBookings.map((booking) => {
    const athleteNames = booking.athleteAttendees
      ? booking.athleteAttendees
          .map((a) => `${a.firstName} ${a.lastName}`)
          .join(", ")
      : booking.name;

    const shortAthleteNames = booking.athleteAttendees
      ? booking.athleteAttendees.map((a) => a.firstName).join(", ")
      : booking.name;

    return {
      title: showAthleteNames ? shortAthleteNames : booking.name,
      tooltipContent: showAthleteNames ? athleteNames : booking.name,
      start: booking.startDateTime
        ? dayjs.tz(booking.startDateTime, dayjs.tz.guess()).utc().toDate()
        : new Date(),
      end: booking.endDateTime
        ? dayjs.tz(booking.endDateTime, dayjs.tz.guess()).utc().toDate()
        : new Date(),
      resource: booking,
    };
  });
  const localizer = dayjsLocalizer(dayjs);

  // Add this function before the return statement
  const eventStyleGetter = (event: any) => {
    // Generate a consistent color based on the bookingGroupItemId
    const colors = [
      "#FF6B6B",
      "#4ECDC4",
      "#45B7D1",
      "#96CEB4",
      "#D4A5A5",
      "#9B59B6",
      "#3498DB",
      "#E67E22",
      "#1ABC9C",
    ];

    const colorIndex = event.resource.bookingGroupId % colors.length;

    return {
      style: {
        backgroundColor: colors[colorIndex],
        borderRadius: "4px",
      },
    };
  };

  // Add these props to the calendar component
  const workingHourProps = {
    min: new Date(2024, 0, 0, 5, 0, 0), // Start time - 5 AM
    max: new Date(2024, 0, 0, 21, 0, 0), // End time - 9 PM
    formats: {
      timeGutterFormat: "ha",
    },
    timeslots: 2, // 30-minute slots (2 slots per hour)
    step: 30, // 30 minutes per step
    showMultiDayTimes: false,
  };

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 2, mb: 2, alignItems: "center" }}>
        <FilterToggleButton
          onClick={() => setShowFilters(!showFilters)}
          endIcon={
            showFilters ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
          }
        >
          <SettingsOutlinedIcon sx={{ fontSize: 18 }} />
          Filters
        </FilterToggleButton>
        <ViewToggle
          control={
            <Switch
              checked={showAthleteNames}
              onChange={(e) => setShowAthleteNames(e.target.checked)}
              size="small"
            />
          }
          label="Show Attendee Names"
        />
      </Box>

      {showFilters && (
        <FilterContainer>
          <StyledFormControl fullWidth>
            <InputLabel id="group-type-filter-label">
              Filter by Group Type
            </InputLabel>
            <Select
              labelId="group-type-filter-label"
              id="group-type-filter"
              multiple
              value={selectedGroupTypes}
              onChange={handleGroupTypeChange}
              renderValue={(selected) =>
                bookingGroups
                  ?.filter((group) => selected.includes(group.id))
                  .map((group) => group.name)
                  .join(", ") || "All"
              }
            >
              {bookingGroups?.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  <Checkbox
                    checked={selectedGroupTypes.indexOf(group.id) > -1}
                  />
                  <ListItemText primary={group.name} />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <StyledFormControl fullWidth>
            <InputLabel id="coach-filter-label">Filter by Coach</InputLabel>
            <Select
              labelId="coach-filter-label"
              id="coach-filter"
              multiple
              value={selectedCoaches}
              onChange={handleCoachChange}
              renderValue={(selected) =>
                nonAthleteUsers
                  ?.filter((user) => selected.includes(user.id))
                  .map((user) => `${user.firstName} ${user.lastName}`)
                  .join(", ") || "All"
              }
            >
              {nonAthleteUsers?.map((user) => (
                <MenuItem key={user.id} value={user.id}>
                  <Checkbox checked={selectedCoaches.indexOf(user.id) > -1} />
                  <ListItemText
                    primary={`${user.firstName} ${user.lastName}`}
                  />
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>

          <TextField
            sx={{ width: "100%", height: "56px" }}
            type="date"
            label="Start Date"
            value={startDate ? startDate.format("YYYY-MM-DD") : ""}
            onChange={(e) => setStartDate(dayjs(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            sx={{ width: "100%", height: "56px" }}
            type="date"
            label="End Date"
            value={endDate ? endDate.format("YYYY-MM-DD") : ""}
            onChange={(e) => setEndDate(dayjs(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FilterContainer>
      )}

      <CalendarContainer>
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 850 }}
          views={["week"]}
          defaultView="week"
          min={workingHourProps.min}
          max={workingHourProps.max}
          formats={workingHourProps.formats}
          timeslots={workingHourProps.timeslots}
          step={workingHourProps.step}
          showMultiDayTimes={workingHourProps.showMultiDayTimes}
          eventPropGetter={eventStyleGetter}
          components={{
            event: EventComponent,
          }}
          onSelectEvent={(event) => {
            setSelectedBooking(event.resource);
            setIsBookingModalOpen(true);
          }}
          onNavigate={(newDate, view, action) => {
            handleLoadMoreBookings(dayjs(newDate));
          }}
        />
      </CalendarContainer>

      <UpdateBookingModal
        isOpenModal={isBookingModalOpen}
        handleCloseModal={handleCloseBookingModal}
        booking={selectedBooking}
        setBooking={setSelectedBooking}
      />
    </Box>
  );
};

export { BookingScheduleCalendarView };
