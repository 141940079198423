import React, { FC, useState } from "react";
import dayjs from "dayjs";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useGetAdminLeaderboardQuery } from "shared/api/healthScores/healthScores";
import { useTypedSelector } from "shared/stores";
import { sharedStyles } from "shared/themes/shared/styles";
import { IdenticonAvatar } from "shared/ui/IdenticonAvatar";
import { Colors } from "shared/themes";
import { styled } from "@mui/system";
import { OutlinedIcon } from "components/OutlinedIcon/OutlinedIcon";

// Add styled components
const LeaderboardContainer = styled(Box)({
  padding: "24px",
});

const StyledTableContainer = styled(TableContainer)({
  backgroundColor: "white",
  border: `1px solid ${Colors.gray[200]}`,
  borderRadius: "16px",
  transition: "all 0.2s ease-in-out",
  "&:hover": {
    transform: "translateY(-2px)",
    boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
  },
});

const DatePickerContainer = styled(Box)({
  display: "flex",
  gap: "16px",
  marginBottom: "24px",
  "& .MuiTextField-root": {
    backgroundColor: "white",
    borderRadius: "8px",
  },
});

const AdminLeaderboard: FC = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const isOpenSidebar = useTypedSelector(
    (state) => state.sidebarState.isOpenSidebar
  );
  const showSidebar = matches && isOpenSidebar;

  const [startDate, setStartDate] = useState<string>(
    dayjs().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState<string>(
    dayjs().endOf("month").format("YYYY-MM-DD")
  );

  const {
    data: leaderboardData,
    isLoading,
    isError,
  } = useGetAdminLeaderboardQuery({ startDate, endDate });

  const containerStyle = showSidebar
    ? sharedStyles.containers.sidebar.content
    : sharedStyles.containers.sidebar.contentFullscreen;

  if (isLoading) {
    return (
      <Box sx={containerStyle}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (isError) {
    return (
      <Box sx={containerStyle}>
        <Typography>Error loading leaderboard data</Typography>
      </Box>
    );
  }

  return (
    <Box sx={containerStyle}>
      <LeaderboardContainer>
        {/* Header Section */}
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h2"
            sx={{
              fontFamily: "Inter",
              fontWeight: "600",
              fontSize: "20px",
              lineHeight: "100%",
              color: Colors.blue[1500],
              mb: 1,
            }}
          >
            <OutlinedIcon iconName="leaderboard" /> Leaderboard
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: "text.secondary", mb: 3 }}
          >
            Monitor athlete performance and rankings
          </Typography>
        </Box>

        {/* Date Pickers */}
        <DatePickerContainer>
          <TextField
            fullWidth
            type="date"
            label="Start Date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />

          <TextField
            fullWidth
            type="date"
            label="End Date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </DatePickerContainer>

        {/* Table */}
        <StyledTableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: Colors.blue[1500],
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Rank
                </TableCell>
                <TableCell
                  sx={{
                    color: Colors.blue[1500],
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Athlete
                </TableCell>
                <TableCell
                  sx={{
                    color: Colors.blue[1500],
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  Score
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leaderboardData?.map((entry) => (
                <TableRow
                  key={entry.userId}
                  sx={{
                    "&:hover": {
                      backgroundColor: Colors.gray[50],
                    },
                    transition: "background-color 0.2s ease",
                  }}
                >
                  <TableCell
                    sx={{
                      color: Colors.gray[900],
                      fontWeight: 500,
                    }}
                  >
                    #{entry.rank} {entry.isRankShared ? "=" : ""}
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IdenticonAvatar
                        seedValue={entry.userId.toString()}
                        profileImageUrl={entry.profileImageUrl}
                        sizeValue={40}
                      />
                      <Typography
                        sx={{
                          ml: 2,
                          color: Colors.gray[900],
                          fontWeight: 500,
                        }}
                      >
                        {entry.name}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      color: Colors.blue[500],
                      fontWeight: 600,
                    }}
                  >
                    {entry.totalScore}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </LeaderboardContainer>
    </Box>
  );
};

export { AdminLeaderboard };
