import { FC, useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Colors } from "shared/themes";
import { useLazyGetUsersQuery, ClientResponse } from "../../shared/api";

interface MultiClientSelectorProps {
  selectedClients: ClientResponse[];
  onClientsChange: (clients: ClientResponse[]) => void;
  searchMode?: "athlete" | "admin" | "coach";
  label?: string;
  placeholder?: string;
}

const MultiClientSelector: FC<MultiClientSelectorProps> = ({
  selectedClients,
  onClientsChange,
  searchMode = "athlete",
  label = "Search to add clients",
  placeholder = "Type a name to search and add multiple clients...",
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [showResults, setShowResults] = useState(false);
  const [trigger, { data: users, isLoading }] = useLazyGetUsersQuery();

  const handleSearch = () => {
    if (!searchValue.trim()) return;
    setShowResults(true);
    trigger({
      userRole: searchMode,
      orderByName: true,
      queryString: searchValue,
      count: 20,
      page: 1,
    });
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSelectClient = (client: ClientResponse) => {
    if (!selectedClients.some((selected) => selected.id === client.id)) {
      onClientsChange([...selectedClients, client]);
    }
    setSearchValue("");
    setShowResults(false);
  };

  const handleRemoveClient = (clientId: number) => {
    onClientsChange(selectedClients.filter((client) => client.id !== clientId));
  };

  // Hide results when clicking outside
  useEffect(() => {
    const handleClickOutside = () => {
      setShowResults(false);
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // Prevent hiding results when clicking inside the component
  const handleBoxClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const selectedClientsLabel =
    searchMode === "athlete" ? "Athletes" : "Coaches";

  return (
    <Box sx={{ mt: 2 }} onClick={handleBoxClick}>
      <Box sx={{ position: "relative" }}>
        <TextField
          fullWidth
          label={label}
          placeholder={placeholder}
          variant="outlined"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onKeyDown={handleSearchKeyDown}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleSearch}
                  edge="end"
                  size="small"
                  type="button"
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {selectedClients.length > 0 && (
        <>
          <Typography
            variant="body2"
            sx={{
              mt: 2,
              mb: 1,
              fontWeight: 500,
              display: "flex",
              alignItems: "center",
              "&::after": {
                content: '""',
                flex: 1,
                borderBottom: `1px solid ${Colors.gray[1600]}`,
                marginLeft: "8px",
              },
            }}
          >
            Selected {selectedClientsLabel} ({selectedClients.length})
          </Typography>
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {selectedClients.map((client) => (
              <Chip
                key={client.id}
                label={`${client.firstName} ${client.lastName}`}
                onDelete={() => handleRemoveClient(client.id)}
                sx={{
                  backgroundColor: Colors.blue[1200],
                  color: Colors.gray[100],
                  fontWeight: 500,
                  "& .MuiChip-deleteIcon": {
                    color: Colors.gray[100],
                    opacity: 0.8,
                    "&:hover": {
                      opacity: 1,
                      color: Colors.gray[100],
                    },
                  },
                  "&:hover": {
                    backgroundColor: Colors.blue[1100],
                  },
                  transition: "background-color 0.2s ease",
                }}
              />
            ))}
          </Box>
        </>
      )}

      {showResults && (
        <Box sx={{ position: "relative" }}>
          {isLoading && (
            <Typography sx={{ mt: 2, color: Colors.gray[1200] }}>
              Searching...
            </Typography>
          )}

          {users && users.length > 0 && (
            <List
              sx={{
                mt: 2,
                maxHeight: 200,
                overflow: "auto",
                border: `1px solid ${Colors.gray[1600]}`,
                borderRadius: "4px",
                position: "absolute",
                left: 0,
                right: 0,
                zIndex: 1000,
                opacity: 1,
                bgcolor: "background.paper",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              {users.map((user) => (
                <ListItem
                  key={user.id}
                  button
                  onClick={() => handleSelectClient(user)}
                  sx={{
                    "&:hover": {
                      backgroundColor: Colors.gray[1700],
                    },
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                    }
                    secondary={
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: "0.75rem", marginTop: "-2px" }}
                      >
                        {user.email && `e: ${user.email}`}
                        {user.phoneNumber && user.email && " • "}
                        {user.phoneNumber && `ph: ${user.phoneNumber}`}
                      </Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          )}

          {users && users.length === 0 && (
            <Typography sx={{ mt: 2, color: Colors.gray[1200] }}>
              No clients found
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

export { MultiClientSelector };
